/*
 * Copyright (C)  Online-Go.com
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */
@keyframes pulsate-paused-clock {
    50% {
        color: #888;
    }
}

// used in Game and GameInfoModal
.rengo-header-block {
    margin-top: 0.7rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.MainGobanView.portrait {
    .rengo-header-block {
        margin-top: 0.15rem;
    }
}

.rengo-header {
    font-size: larger;
    align-self: center;
    border-radius: 0.4em;
    padding: 0.3rem;
    margin-bottom: 0.25rem;
    themed: background-color shade4;
}

.MainGobanView .players {
    .rengo-team-members {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex-grow: 1;
        margin: 0.3rem;

        .rengo-team-member {
            border-radius: 0.4em;
            padding: 0.3rem;
            padding-bottom: 0.1rem;
            width: 100%;
            box-sizing: border-box;
            text-align: left;
            themed: background-color other-player-background;
        }
    }
}

.MainGobanView .player-icons {
    flex-shrink: 0;
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    min-height: 2.7rem;
    flex-direction: row;
    box-sizing: border-box;
    padding: 0.1rem;
    justify-content: space-around;

    .player-container {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 49%;
        box-sizing: border-box;
        border-radius: 0.3em;
        text-align: center;
        padding: 0.2em;
        margin-top: 0.2em;

        /* border: 1px solid transparent; */
        .komi {
            flex-grow: 0;
            text-align: right;
            font-weight: bold;
        }

        .rules {
            flex-grow: 0;
            text-align: right;
            font-weight: bold;
        }

        .captures, .points {
            font-weight: bold;
            position: relative;
            background-image: none;
            font-size: 1.1rem;
        }

        .hidden {
            visibility: hidden;
        }

        .auto-resign-overlay {
            opacity: 0.9;
            z-index: z.goban.score-details;
            background-color: #555;
            color: #fff;
            display: flex;
            flex-direction: column;
            align-items: center;
            align-content: center;
            justify-content: center;
            font-size: font-size-big;

            .fa-bolt {
                font-size: 150%;
            }

            padding-left: 2px;
            min-width: inherit; // make sure we are as wide as the player avatar that contains us
        }
    }

    .player-icon-clock-row {
        display: flex;
        align-items: stretch;
        width: 100%;
    }

    .player-icon-container {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: 2rem;
        display: inline-flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        // width: 100%;
        background-repeat: no-repeat;
        background-size: contain;
        min-height: 4rem;
        min-width: 4rem;
    }

    /.MainGobanView.squashed {
        .player-icon-container {
            min-height: 2rem;
            min-width: 2rem;

            .player-flag {
                top: 1rem;
                left: 1rem;
                transform-origin: 6px 10px;
            }
        }
    }

    .white.player-container {
        box-shadow: 0 2px 5px rgba(50, 50, 50, 0.51);
        themed: background player-white-background;
        themed: color player-white-fg;

        .paused-correspondence {
            themed: color player-white-paused-clock;
        }

        // Use the light mode color for names in the white player box
        .Player {
            color: light.user;
        }

        for color-attr, class-attr in special-player-type-priority {
            .Player.{class-attr} {
                color: light[color-attr] !important;
            }
        }
    }

    .black.player-container {
        themed: background player-black-background;
        themed: color player-black-fg;
        border-top: 1px solid #202020;
        box-shadow: 0 2px 5px rgba(20, 20, 20, 0.71);

        .paused-correspondence {
            themed: color player-black-paused-clock;
        }

        // Use the dark mode color for names in the black player box
        .Player {
            color: dark.user;
        }

        for color-attr, class-attr in special-player-type-priority {
            .Player.{class-attr} {
                color: dark[color-attr] !important;
            }
        }
    }

    .player-container.shortgame.offline {
        border: 1px solid #f66 !important;
    }

    .player-name-plain {
        font-size: 1.1em;
    }

    .player-flag {
        position: absolute;
        display: inline-block;
        left: 3rem;
        top: 3rem;
        transform: scale(0.5);
        transform-origin: 4px 12px;

        .flag {
            vertical-align: bottom;
            margin-bottom: 0;
        }
    }

    .black.player-container .flag, .white.player-container .flag {
        margin-top: 0.1em;
        margin-right: 0.4em;
        margin-left: 0.4em;
    }

    .player-name-container {
        overflow: hidden;
        text-align: left;
    }

    .player-name {
        display: inline-block;
        font-size: 1.1em;
        margin-top: 0.2em;
    }

    // This is the chat presence indicator in the avatar on the game page
    .ChatPresenceIndicator {
        position: absolute;
        display: inline-block;
        left: 0.25em;
        top: 0.25em;
        font-size: 0.5em !important;
        vertical-align: text-top;

        &.online {
            themed: color indicate-online;
            themed: text-shadow indicate-online-shadow;
        }
    }

    // note: I have not been able to find a place that generates an online-status-indicator class
    // if there is one, then this should be themed properly.
    .white.player-container {
        .online-status-indicator.online {
            // color: #00ff00;
            color: pink; // / flush it out if it is being used
        }
    }

    #game-black-clock {
        themed: color player-black-clock;
    }

    #game-white-clock {
        themed: color player-white-clock;
    }

    #game-black-clock .out_of_time, #game-white-clock .out_of_time {
        color: #f44;
    }

    #game-black-clock .plenty_of_time, #game-white-clock .plenty_of_time {
        color: #000;
    }

    #game-black-clock .time_suffix {
        color: #aaa;
        font-size: 0.7em;
    }

    #game-white-clock .time_suffix {
        color: #111;
        font-size: 0.7em;
    }

    #game-black-clock .low_time, #game-white-clock .low_time {
        color: #f44 !important;
        font-weight: bold;
    }

    #black_score, #white_score, .clock-container {
        font-weight: bold;
    }

    .score-container {
        margin-top: 0.5em;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        position: relative;
        user-select: none;
        cursor: help;

        .score-details {
            position: absolute;
            right: 0;
            top: 1.2rem;
            min-width: 100%;
            border: 1px solid #999;
            themed: background-color shade4;
            themed: color fg;
            box-shadow: 2px 2px 1px #888888;
            border-radius: 2px;
            visibility: hidden;
        }

        &.show-score-breakdown .score-details {
            z-index: z.goban.score-details;
            visibility: visible;
            font-size: 1.2rem;
        }
    }

    .score_breakdown {
        font-size: 0.8em;
        text-align: right;
        padding-right: 0.3em;
    }

    .score_breakdown .summary {
        text-align: center;
    }

    .score_breakdown div div {
        display: inline-block;
        width: 3em;
    }

    #score-estimation {
        font-size: 1.3em;
        font-weight: bold;
        padding-bottom: 1em;
    }

    .Clock {
        .main-time {
            font-size: 14pt;
        }
    }
}

.MainGobanView.portrait .player-icons {
    .player-icon-container {
        flex-basis: 1.5rem;
        min-height: 1.5rem;
        min-width: 4rem; // need this width to accomodate disconnect timer
        position: relative;

        .player-flag {
            position: absolute;
            left: unset;
            top: unset;
            right: 0.1rem;
            bottom: -0.2rem;
            transform-origin: 40px bottom;
        }

        .auto-resign-overlay {
            flex-direction: row;
            font-size: inherit;

            .fa-bolt {
                font-size: inherit;
                margin-right: 2px;
            }
        }
    }

    .player-name-container {
        font-size: font-size-small;
    }

    .player-container {
        .captures, .points, .komi, .rules {
            font-size: font-size-small;
        }

        padding-bottom: 0;
    }

    .score-container {
        margin-top: 0;
    }

    .Clock {
        .main-time {
            font-size: 1.2em;

            &.small {
                font-size: font-size-small;
            }
        }

        .byo-yomi-container, .canadian-container {
            font-size: 0.8 * 1.2em;
        }
    }
}

body.dark .MainGobanView.zen .player-icons {
    .white {
        &.player-container {
            background: linear-gradient(#2C2C2C 0%, #383838 100%);
            border: 1px solid #181818;
            color: #DDD;
        }
    }

    #game-white-clock, .white .score-container {
        color: #DDD;
    }

    .black {
        &.player-container {
            background: linear-gradient(#111111 0%, #1A1A1A 100%);
            // background: none;
            background-color: #111111;
            border: 1px solid #181818;
            color: #BBB;
        }
    }

    #game-black-clock, .black .score-container {
        color: #BBB;
    }
}

body.accessible .MainGobanView.zen .player-icons {
    .white {
        &.player-container {
            background: linear-gradient(#2C2C2C 0%, #383838 100%);
            border: 1px solid #181818;
            color: #DDD;
        }
    }

    #game-white-clock, .white .score-container {
        color: #DDD;
    }

    .black {
        &.player-container {
            background: linear-gradient(#111111 0%, #1A1A1A 100%);
            // background: none;
            background-color: #111111;
            border: 1px solid #181818;
            color: #BBB;
        }
    }

    #game-black-clock, .black .score-container {
        color: #BBB;
    }
}

body.light .MainGobanView.zen .player-icons {
    .black.player-container {
        background: linear-gradient(#666 0%, #181818 100%);
    }
}

.MainGobanView.zen .play-controls {
    .game-state {
        display: none;
    }
}

.MainGobanView.zen {
    .rengo-header, .rengo-teams-header, .rengo-team-members-container {
        display: none;
    }

    .player-icons {
        .black.player-container {
            border-top: none;
        }

        .player-container {
            justify-content: center;
            min-width: 150px;
        }

        .player-icon-clock-row {
            width: auto;
            flex-grow: 1;
        }

        .Clock {
            min-height: 80px;

            .pause-text {
                display: none;
            }

            .main-time, .period-time {
                line-height: 50px;
                font-size: 26pt;
                text-align: center;
            }

            .byo-yomi-container, .canadian-clock-container {
                display: block;
                font-size: font-size-mid;
                flex-grow: 1;
            }
        }

        .Clock:not(.in-overtime) {
            .byo-yomi-container, .canadian-clock-container {
                display: none;
            }
        }

        .captures {
            display: flex;
            justify-content: center;
            flex-basis: 70%;
        }

        .score-container {
            margin: 0px;
            justify-content: center;
            flex-grow: 0;
        }

        .num-captures-container {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: row;
            flex-grow: 1;

            .stone-image {
                width: 14px;
                height: 14px;
            }

            .num-captures-stone {
                margin-left: 3px;
                margin-right: 3px;
            }
        }

        .player-icon-container, .player-name-container, .player-name-plain, .score-details, .rules, .komi {
            display: none;
        }
    }
}
