/*
 * Copyright (C)  Online-Go.com
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */
.seek-graph-legend {
    margin: 1rem 0;
    // display: flex;
    width: 100%;

    canvas {
        margin-right: 2px;
        width: 20px;
        height: 10px;
    }

    td {
        padding: 0 1rem;
        vertical-align: middle;
    }

    .legend-item {
        margin: 0 5px;
        display: flex;
        align-items: center;
        white-space: nowrap;
    }

    @media (max-width: 720px) {
        .legend-group {
            width: 50%;
        }
    }

    @media (min-width: 720px) {
        .legend-group {
            width: 100%;
        }
    }

    .grid {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(125px, 1fr));
        // display: flex;
        // flex-wrap: wrap;
        // align-items: center;
    }
}