/*
 * Copyright (C)  Online-Go.com
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */
.popover-backdrop {
    position: fixed;
    display: flex;
    // min-height: 100%;
    // height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: z.popover;
    align-items: flex-start;
    align-content: stretch;
    justify-content: center;
}

.popover-fadeout {
    transition-property: opacity;
    transition-duration: 0.5s; // matches corresponding delay in fadeout()
    opacity: 0%;
}

.popover-container {
    position: absolute;
    display: inline-block;
    z-index: z.popover;
}
