.MessageTemplate {
    display: inline-flex;
    flex-direction: column;
    width: 49%;

    @media only screen and (max-width: 767px) {
        width: 100%;
    }

    textarea {
        min-height: 10rem;
    }

    .buttons {
        margin-top: 1rem;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    h3 {
        margin-bottom: 0.5rem;
    }

    .top {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1rem;

        select {
        }

        .clear {
            margin-left: 1rem !important;
        }

        .log {
            display: inline-flex;
            flex-direction: column;
            align-items: center;
            user-select: none;
            cursor: pointer;
        }

        label {
            cursor: pointer;
        }
    }
}
