/*
 * Copyright (C)  Online-Go.com
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */
indiciator-size = 1.5rem;

.notification-indicator {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    display: none;
    themed: background-color primary;
    // border-radius: 50%;
    border: 0;
    // width: 0.5rem;
    // height: 0.5rem;
    height: 3px;

    &.active {
        display: inline-block;
    }
}

.notification-indicator-count {
    position: absolute;
    top: 0;
    left: 24px;
    display: none;
    font-size: 0.7rem;
    width: 1rem;
    border-radius: 50%;
    text-align: center;
    padding-bottom: 0.1rem;
    themed: color colored-background-fg;
    themed: background-color primary;

    &.active {
        display: inline-block;
    }
}

notification-list-height = 14rem;

.NotificationList {
    display: block;
    height: notification-list-height;

    .no-notifications {
        display: flex;
        height: notification-list-height;
        align-items: center;
        justify-content: center;
    }

    .contents {
        display: flex;
        flex-direction: column;
        height: notification-list-height;

        .list {
            flex-grow: 1;
            overflow-x: hidden;
            overflow-y: auto;
        }

        .clear {
            flex-grow: 0;
            text-align: right;
            padding-top: 0.5rem;
            padding-right: 0.5rem;
        }
    }

    .notification {
        min-height: 3rem;
        border-bottom: 1px solid transparent;
        themed: border-bottom-color shade3;
        padding: 0.3rem;

        .buttons {
            text-align: right;

            .fab.reject {
                margin-right: 2rem;
            }
        }
    }

    .notification.clickable:hover {
        themed: background-color shade5;
    }

    .notification.challenge {
        .description {
            font-style: italic;
        }
    }

    .late-notification-text {
        white-space: pre;
    }

    .late-notification a {
        themed: color link-color; // this is overriden by a mix-in for some reason :(
        text-decoration: underline;
    }

    .fa-times-circle {
        float: right;
        font-size: 1.3rem;
        themed: color shade3;
    }

    .fa-times-circle:hover {
        cursor: pointer;
        themed: color fg;
    }

    .moderation-offer {
        display: flex;
        flex-direction: column;
    }
}
