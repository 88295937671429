// This element is intended to be a red "no wifi" indicator
// positioned over the top of almost everything, and in a
// place on the screen that is relatively uncluttered in both
// normal and zen mode for games.

// The "no wifi" indicator is assembled from two font awesome icons
// superimposed on each other at their centres by using flex `center`,
// and has the "wifi" element flashing for extra attention grabbing
// coolness.
.NetworkStatus {
    display: inline-flex;
    position: absolute;
    z-index: z.network-warning;
    top: 0.5em;
    right: 0;
    min-width: 10rem;
    height: 2.5rem;
    white-space: nowrap;
    themed: background-color reject;
    themed: color colored-background-fg;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    padding-right: 1rem;
    border-radius: 0.3rem;
    cursor: pointer;

    // if we're not on the game page, we make this more discreet...
    &.non-game {
        transform: scale(0.5);
        top: -0.75rem; // its peculiar why 0 doesn't work - something about scaling after positioning?
        themed: color reject;
        background-color: transparent !important; // important needed to override "theme" specifier
        min-width: 0;
        padding-right: 0;

        .icon {
            margin-right: 0;
        }
    }

    .icon {
        position: relative;
        display: inline-flex;
        width: 2rem;
        height: 2rem;
        align-items: center;
        justify-content: center;
    }

    .icon.no-wifi {
        margin-right: 1rem;
    }

    @keyframes fadeInOut {
        0% {
            opacity: 1;
        }

        50% {
            opacity: 0.5;
        }

        100% {
            opacity: 1;
        }
    }

    i {
        position: absolute;
    }

    i.fa-2x {
        opacity: 0.6;
    }

    i.fa-wifi {
        animation: fadeInOut 2s infinite;
    }
}
