/*
 * Copyright (C)  Online-Go.com
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */
.PaginatedTable {
    position: relative;
    max-width: 100vw;

    div {
        overflow-x: auto;

        table {
            width: 100%;
            max-width: 100vw;
            border-collapse: collapse;

            tr {
                border-bottom: solid 1px transparent;
                themed: border-bottom-color shade4;
            }

            th {
                text-align: left;
                color: #919191;
                font-weight: normal;
            }
        }
    }

    .loading-overlay {
        display: none;
        user-select: none;
    }

    &.loading {
        .loading-overlay {
            display: flex;
            width: 100%;
            top: 1.5rem;
            bottom: 2rem;
            position: absolute;
            themed: background-color shade3;
            opacity: 0.5;
            align-items: center;
            align-content: center;
            justify-content: center;
            font-size: 2rem;
            z-index: 1;
        }
    }

    .name, .player {
        word-wrap: break-word;
        white-space: normal;
    }

    .name {
        min-width: 8rem;
    }

    .player {
        min-width: 5rem;
    }

    &.stripped {
        tr:nth-child(odd) {
        }

        tr:nth-child(even) {
            themed: background-color shade4;
        }
    }

    &.clickable-rows {
        tbody tr {
            cursor: pointer;

            &.queued {
                themed: background-color danger;

                &:hover {
                    themed: background-color warning-bg;
                }
            }
        }

        tbody tr:hover {
            themed: background-color shade5;
        }
    }

    .page-controls {
        display: flex;
        align-items: flex-end;
        margin-top: 1rem;

        .left {
            display: flex;
            flex: 1;
            align-items: center;

            input {
                // font-size: 0.7em;
                padding: 0;
                width: 2rem;
                text-align: right;
                border: 0;
                margin-left: 0.25rem;
                margin-right: 0.5rem;
                outline: none;
                // border-bottom: 1px solid;
                // background-color: transparent;
                // themed color fg
            }

            .of {
                // font-size: 0.7em;
            }

            .total {
                display: inline-block;
                // font-size: 0.7em;
                min-width: 0.8rem;
                text-align: right;
                margin-right: 1rem;
            }

            .fa {
                font-size: 0.9em;
                width: 0.7rem;
                cursor: pointer;
            }
        }

        .right {
            display: inline-block;
            flex: 1;
            text-align: right;
        }
    }

    select {
        // font-size: 0.7em;
    }

    .sort-link, .sort-link:hover {
        color: inherit;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
}
