/*
 * Copyright (C)  Online-Go.com
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */
.Clock {
    & {
        flex: 1;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-end;
        align-items: center;

        .main-time {
            flex-grow: 1;
            display: inline-block;
        }

        &.paused {
            animation-name: pulsate-paused-clock;
            animation-duration: 1s;
            animation-timing-function: step-end;
            animation-iteration-count: infinite;
        }

        .overtime-container {
            flex-grow: 1;
            display: inline-block;
        }

        .periods-container {
            display: flex;
            justify-content: center;
            align-items: stretch;
            align-content: stretch;

            div {
                // margin: auto;
                margin-left: 0.5em;
                margin-right: 0.5em;
            }
        }

        .period-moves {
            display: inline-block;
        }

        .period-time {
            display: inline-block;
        }

        .byo-yomi-periods {
            display: inline-block;
            margin-left: 0.5rem;

            &.sudden-death {
                color: red;
            }
        }

        .overtime {
            font-size: 6pt;
            text-align: center;
        }

        .pause-and-transmit {
            width: 100%;
            font-size: font-size-small;
            margin-left: 0.5rem;
            text-align: right;

            .pause-text {
                margin-left: 0.5rem;
            }

            .transmitting {
                margin-right: 0.5rem;
                width: 0.8rem;
                display: inline-block;
            }
        }

        .boxed {
            text-align: right;
        }
    }

    &.start-mode {
        &.black {
            color: #63A7FF !important;
        }

        &.white {
            color: #147AFF !important;
        }
    }

    &.in-overtime {
        .overtime {
            font-weight: bold;
            color: #a55 !important;
        }

        .boxed {
            border-color: #a55 !important;
        }
    }

    &.mini-goban {
        float: right;
        flex: auto;
        flex-shrink: 0;
        flex-grow: 1;
        flex-basis: auto;

        &.paused {
            color: #888;
        }

        &.in-overtime {
            .periods, .period-time, .byo-yomi-periods {
                font-size: font-size-small;
            }
        }
    }
}

.MainGobanView {
    .byo-yomi-container, .canadian-container {
        font-size: font-size-mid;
    }
}

.MiniGoban {
    .pause-and-transmit {
        width: auto;
        display: inline-block;
        margin-left: 0;

        .pause-text {
            margin-left: 0;
        }

        .transmitting {
            margin-left: 0;
            width: 0;
        }
    }
}
