/*
 * Copyright (C)  Online-Go.com
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

@require '../node_modules/sweetalert2/dist/sweetalert2.css';
@require '../node_modules/react-table/react-table.css';
@require '../submodules/react-dynamic-help/src/DynamicHelp.css';

font-size-big = 16pt
font-size-mid = 14pt
font-size-normal = 12pt
font-size-small = 10pt
font-size-smaller = 8pt
font-size-extra-small = font-size-smaller
font-size-normal = 12pt
dock-collapsed-width=2rem;
dock-width=15rem;
private-chat-width=20rem;

navbar-height = var(--navbar-height)
below-navbar = var(--below-navbar)

:root {
    --navbar-height: 3rem;
    --below-navbar: 3.3rem;
}
body.v6 {
    // experimental
}

@media (max-height: 500px) {
    :root {
        --navbar-height: 2.0rem;
        --below-navbar: 2.3rem;
    }
    body.v6 {
        // experimental
    }
}


/* z indices */
z = {
    goban: {
        shadow-layer   : 10,
        stone-layer    : 20,
        pen-layer      : 30,
        message        : 40,
        tab-icon       : 160, /* must be above z.dock */
        exit-zenmode   : 45,
        score-details  : 50,
    },
    seek-graph: {
        challenge-list : 150,
    }
    dock               : 150,
    banned-banner      : 151,
    private-chat       : 153,
    incident-list      : 155,
    omnisearch         : 160,
    announcements      : 180,
    toast              : 200,
    friend-list: {
        backdrop       : 300,
        menu           : 310
    },
    nav-menu: {
        backdrop       : 1000,
        menu           : 1010,
        omnisearch     : 1100,
    },
    modal: {
        backdrop       : 2000,
        container      : 2010,
    },
    popover            : 5000,
    infoball           : 5010,
    report             : 6000,
    swal               : 10000,
    challenge-link     : 10010,  // We have to say "Challenge Link Copied" over the top of "Challenge Created"

    dynamic-help       : 20000,  // Help popups would like to go on top of everything

    account-warning    : 50003,
    network-warning    : 50004,
    superchat-modal    : 50005,
    superchat          : 50010,
}

supporter-gold = #dfba00

special-player-type-priority = {
    supporter: 'supporter'
    professional: 'professional'
    ambassador: 'ambassador'
    teacher: 'teacher'
    bot: 'bot'
    moderator: 'moderator'
    aga-official: 'aga'
    admin: 'admin'
}

light = {}
dark = {}
accessible = {}

themes = {}
themes.light = light
themes.dark = dark
themes.accessible = accessible


light.clear = transparent
dark.clear = transparent
accessible.clear = transparent


/*************/
/*** LIGHT ***/
/*************/

//light.bg                                = #f0f0f0
light.bg                                = #fff
light.fg                                = #202020
light.fg-increased-contrast             = #000000
light.mix-base                          = #999
light.background-color                  = light.bg
light.card-background-color             = darken(light.bg, 3%)
light.text-color                        = light.fg
light.text-color-active                 = darken(light.text-color, 50%)
light.spectator-text-color              = #0A06A8
light.default-button-color              = light.fg
light.toast-bg                          = light.fg
light.toast-fg                          = light.bg
light.link-color                        = #0068F7
light.link-color-active                 = darken(light.link-color, 20%)


light.default-button                    = #E6E6E0
light.primary                           = #2480FF
light.danger                            = #FFA600
//light.success                           = #00C600
light.success                           = #2AD131
light.success                           = #32C738 // make up your mind? ;)
//light.info                              = #A41EFF
light.info                              = #C46EFF
light.info                              = #C376FE  // make up your mind? ;)
light.reject                            = #FF410F
light.colored-background-fg             = #fff
light.malkovich-bg                      = #CCE3D5
light.personal-bg                       = lightblue
light.hidden-bg                         = #D6B1F4
light.variation                         = #0088cc
light.variation-hover                   = #005580
light.email-banner                      = #CEDBCF
light.chart-area                        = #E6F6FE
light.chart-line                        = #184A6B
light.error-boundary-bg                 = lighten(light.reject, 50%)
light.error-boundary-fg                 = darken(light.reject, 50%)
light.warning-bg                        = #FFAE2C
light.warning-fg                        = light.fg
light.supporter-error-bg                = hsl(23, 100, 90);
light.supporter-error-border            = hsl(23, 100, 80);


//light.help-popup-bg                     = #D471DA
light.help-popup-bg                     = light.primary

light.indicate-online                   = #6f6
light.indicate-online-shadow            = -1px -1px 0 #afa,  1px -1px 0 #8c8, -1px 1px 0 #8c8, 1px 1px 0 #6a6;
light.chat-offline                      = #ccc
light.chat-offline-shadow               = -1px -1px 0 #ccc,  1px -1px 0 #ccc, -1px 1px 0 #ccc, 1px 1px 0 #ccc;

light.user                              = #3070A7
light.supporter                         = #A48200;  // Approximately darker(supporter-gold, 30%)
light.aga-official                      = #B95200;
light.professional                      = green;
light.bot                               = #888;
light.moderator                         = #9733EE
light.admin                             = light.moderator
light.ambassador                        = #6FCDEA
light.teacher                           = #27AD49
light.chat-mentions                     = #DB397A // pink
light.chat-self-message                 = #0449C4
light.chat-system                       = #1CA760
light.private-chat-user                 = #111111

light.win                               = #73D355
light.tie                               = #F3F3D5
light.loss                              = #BC4B9A

light.weak-win                          = lighten(light.win, 25%)
light.weak-loss                         = lighten(light.loss, 25%)

light.strong-win                        = darken(light.win, 25%)
light.strong-loss                       = darken(light.loss, 25%)

light.strong-w-text                     = light.bg
light.strong-l-text                     = light.bg
light.weak-w-text                       = light.fg
light.weak-l-text                       = light.fg

light.winner-trophy                     = #e6c525
light.annul-opacity                    = 0.5

light.shade0                            = lighten(light.fg, 20%)
light.shade1                            = lighten(light.fg, 40%)
light.shade2                            = lighten(light.fg, 60%)
light.shade3                            = lighten(light.fg, 80%)
light.shade4                            = lighten(light.fg, 90%)
light.shade5                            = lighten(light.fg, 95%)

light.search-highlight                  = #FFEA00

light.other-player-background           = rgba(150, 150, 150, .3)

// these are currently the same colour as painted on the board for positions of this type.
// may need tweaking to look better...
light.joseki-ideal                      = #008300
light.joseki-good                       = #436600
light.joseki-trick                      = #ffff00
light.joseki-question                   = #00ccff
light.joseki-mistake                    = #b3001e

/** React Select colors **/

light.react-select-bg                   = light.bg
light.react-select-border               = light.shade3
light.react-select-selected-bg          = #2684FF
light.react-select-selected-fg          = #FFFFFF
light.react-select-focused-bg           = #DEEBFF
light.react-select-focused-fg           = light.fg

/** Navbar **/

//light.navbar-highlight-background-color = lighten(light.bg, 5%);
//light.navbar-background-color           = darken(light.bg, 5%);
light.navbar-highlight-background-color = light.bg;
light.navbar-background-color           = light.bg;
light.logo-svg                          = svg-load("../assets/ogs_light.svg")

/** Input **/

light.input-bg                          = darken(light.bg, 3%)
light.input-border                      = lighten(light.fg, 50%)
light.input-fg                          = light.fg

/** MiniBoards **/

light.goban-shadow                      = 0 4px 8px rgba(128,128,128,.71)
light.goban-shadow-borderless           = 0 0px 0px rgba(128,128,128,.71)
light.miniboard-to-move                 = #E6FFD8
light.miniboard-to-move-border          = darken(light.miniboard-to-move, 60%)
light.miniboard-viewed-to-move          = #F3F3F3
light.miniboard-viewed-to-move-border   = darken(light.miniboard-viewed-to-move, 10%)
light.miniboard-hover                   = #C7E7E9
light.miniboard-hover-border            = darken(light.miniboard-hover, 30%)
light.miniboard-stone-removal           = #FBfbD8
light.miniboard-stone-removal-border    = darken(light.miniboard-stone-removal, 45%)
light.miniboard-stone-removal-hover     = lighten(light.miniboard-stone-removal, 45%)
light.miniboard-stone-removal-hover-border= darken(light.miniboard-stone-removal-hover, 45%)
light.player-black-background           = linear-gradient(to bottom, #7d7e7d 0%, #0e0e0e 100%);
light.player-white-background           = linear-gradient(to bottom, #cccccc 0%, #eeeeee 30%, #ffffff 100%);
light.inactive-support-gradient         = linear-gradient(to bottom, #ffffff 0%, #e5e5e5 100%);
light.active-support-gradient           = linear-gradient(to bottom, #ffffff 0%, lighten(supporter-gold, 70%) 100%);
light.player-black-fg                   = #eeeeee
light.player-white-fg                   = light.fg
light.player-black-clock                = lighten(light.player-black-fg, 50%)
light.player-white-clock                = light.player-white-fg
light.player-black-paused-clock         = #ABA8AD;
light.player-white-paused-clock         = #928A99;
light.quickmatch-player-waiting         = darken(light.success, 10%)

/************/
/*** DARK ***/
/************/
core-color-darken-amount = 20%

dark.bg                                 = #1a1a1a
dark.fg                                 = #bbbbbb
dark.fg-increased-contrast              = #ffffff
dark.mix-base                           = #777
dark.background-color                   = dark.bg
dark.card-background-color              = lighten(dark.bg, 7%)
dark.text-color                         = dark.fg
dark.spectator-text-color               = #8987FC
dark.text-color-active                  = lighten(dark.text-color, 50%)
dark.toast-bg                           = dark.fg
dark.toast-fg                           = dark.bg
dark.link-color                         = #539bff
dark.link-color-active                  = darken(dark.link-color, 20%)

dark.default-button                     = lighten(dark.bg, 20%)
dark.default-button-color               = dark.fg
dark.primary                            = darken(light.primary, core-color-darken-amount)
dark.danger                             = darken(light.danger, core-color-darken-amount)
dark.success                            = darken(light.success, core-color-darken-amount)
dark.info                               = darken(light.info, core-color-darken-amount)
dark.reject                             = darken(light.reject, core-color-darken-amount + 10%)
dark.colored-background-fg              = #eeeeee
dark.malkovich-bg                       = #597053
dark.personal-bg                        = darken(light.personal-bg, 70%)
dark.hidden-bg                          = #300352
dark.variation                          = #00aaff
dark.variation-hover                    = #1A93D0
dark.email-banner                       = #2A492B
dark.chart-area                         = #092D3E
dark.chart-line                         = #329BE0
dark.error-boundary-bg                  = darken(light.reject, 50%)
dark.error-boundary-fg                  = lighten(light.reject, 50%)
dark.warning-bg                         = #925A00
dark.warning-fg                         = dark.fg
dark.supporter-error-bg                 = hsl(23, 100, 10);
dark.supporter-error-border             = hsl(23, 100, 30);

//dark.help-popup-bg                     = #4E134C
dark.help-popup-bg                      = dark.primary

dark.indicate-online                    = #6f6
dark.indicate-online-shadow             = -1px -1px 0 #afa,  1px -1px 0 #8c8, -1px 1px 0 #8c8, 1px 1px 0 #6a6;
dark.chat-offline                       = #ccc
dark.chat-offline-shadow                = -1px -1px 0 #ccc,  1px -1px 0 #ccc, -1px 1px 0 #ccc, 1px 1px 0 #ccc;

dark.user                               = #9dc6ff
dark.supporter                          = supporter-gold;
dark.aga-official                       = light.aga-official;
dark.professional                       = green;
dark.bot                                = light.bot
dark.moderator                          = lighten(light.moderator, 20%)
dark.admin                              = lighten(light.admin, 20%)
dark.ambassador                         = light.ambassador
dark.teacher                            = light.teacher

dark.shade0                             = lighten(dark.bg, 70%)
dark.shade1                             = lighten(dark.bg, 50%)
dark.shade2                             = lighten(dark.bg, 35%)
dark.shade3                             = lighten(dark.bg, 25%)
dark.shade4                             = lighten(dark.bg, 20%)
dark.shade5                             = lighten(dark.bg, 13%)

dark.search-highlight                  = #554E00

dark.other-player-background           = rgba(140, 140, 140, .2)

dark.chat-mentions                      = #E36497 // pink
dark.chat-self-message                  = #8AB1E6
dark.chat-system                        = lighten(light.chat-system, 20%)
dark.private-chat-user                  = #eeeeee


dark.win                                = desaturate(darken(light.win, 30%), 20%)
dark.tie                                = desaturate(darken(light.tie, 80%), 20%)
dark.loss                               = desaturate(darken(light.loss, 30%), 20%)

dark.strong-win                         = lighten(dark.win, 25%)
dark.strong-loss                        = lighten(dark.loss, 25%)

dark.weak-win                           = darken(dark.win, 25%)
dark.weak-loss                          = darken(dark.loss, 25%)

dark.strong-w-text                      = dark.bg
dark.strong-l-text                      = dark.bg
dark.weak-w-text                        = dark.fg
dark.weak-l-text                        = dark.fg

dark.winner-trophy                     = #e6b500
dark.annul-opacity                    = 0.3

/** React Select colors **/

dark.react-select-bg                   = dark.shade4
dark.react-select-border               = dark.shade5
dark.react-select-selected-bg          = #2684FF
dark.react-select-selected-fg          = #FFFFFF
dark.react-select-focused-bg           = #435B80
dark.react-select-focused-fg           = #ffffff

/** Navbar **/

dark.navbar-highlight-background-color  = #333
dark.navbar-background-color            = lighten(dark.bg, 5%)
dark.logo-svg                           = svg-load("../assets/ogs_dark.svg")

/** Input **/

dark.input-bg                           = lighten(dark.bg, 30%)
dark.input-border                       = lighten(dark.bg, 30%)
dark.input-fg                           = dark.fg


/** MiniBoards **/

dark.goban-shadow                       = none
dark.goban-shadow-borderless            = none
dark.miniboard-to-move                  = #2B4029
dark.miniboard-to-move-border           = darken(dark.miniboard-to-move, 60%)
dark.miniboard-viewed-to-move           = #282828
dark.miniboard-viewed-to-move-border    = lighten(dark.miniboard-viewed-to-move, 10%)
dark.miniboard-hover                    = #578588
dark.miniboard-hover-border             = darken(dark.miniboard-hover, 30%)
dark.miniboard-stone-removal            = #5B4810
dark.miniboard-stone-removal-border     = darken(dark.miniboard-stone-removal, 45%)
dark.miniboard-stone-removal-hover      = darken(dark.miniboard-stone-removal, 45%)
dark.miniboard-stone-removal-hover-border= darken(dark.miniboard-stone-removal-hover, 45%)
dark.player-black-background            = linear-gradient(to bottom, #7d7d7d 0%, #333333 50%, #0c0c0c 100%);
dark.player-white-background            = linear-gradient(to bottom, #888888 0%, #efefef 50%, #efefef 100%);
dark.inactive-support-gradient          = linear-gradient(to bottom, #7d7d7d 0%, #333333 100%);
dark.active-support-gradient            = linear-gradient(to bottom, darken(supporter-gold, 30%) 0%, darken(supporter-gold, 60%) 100%);
dark.player-black-fg                    = dark.fg
dark.player-white-fg                    = darken(dark.fg, 90%)
dark.player-black-clock                 = lighten(dark.player-black-fg, 50%)
dark.player-white-clock                 = #000
dark.player-white-paused-clock          = #5F4A71;
dark.player-black-paused-clock          = #928A99;

dark.joseki-ideal                       = #DBAF00;
dark.quickmatch-player-waiting          = dark.success

/******************/
/*** ACCESSIBLE ***/
/******************/

// A theme intended to improve accessibility for vision impaired
// ... though some say it's simply better :)

// Start with the previous best theme...

for key, value in dark
  accessible[key] = value

// And add the different stuff...

accessible.win                                = #0072B2  // blue from https://www.nature.com/articles/nmeth.1618/figures/2
accessible.tie                                = #CC79A7  // reddish purple from there ^^
accessible.loss                               = #D55E00  // vermillion from there ^^

// get rid of green indicator light, colors chosen for apparent "looks good" against the bg in this context
accessible.indicate-online                    = #70b3ff
accessible.indicate-online-shadow             = -1px -1px 0 #000,  1px -1px 0 #111, -1px 1px 0 #222, 1px 1px 0 #111;

accessible.chat-offline                       = accessible.bg;
accessible.chat-offline-shadow                = -1px -1px 0 #ccc,  1px -1px 0 #ccc, -1px 1px 0 #ddd, 1px 1px 0 #ccc;

accessible.strong-win                         = accessible.win
accessible.strong-loss                        = accessible.loss

accessible.weak-win                           = darken(accessible.win, 35%)
accessible.weak-loss                          = darken(accessible.loss, 35%)

accessible.quickmatch-player-waiting          = accessible.indicate-online


:root {
    --primary: light.primary;
    --danger: light.danger;
    --success: light.success;
    --info: light.info;
    --reject: light.reject;
    --select-header-bg: light.shade5;
    --select-header-fg: light.fg;
}

body.accessible {
    --primary: accessible.primary;
    --danger: accessible.danger;
    --success: accessible.success;
    --info: accessible.info;
    --reject: accessible.reject;
    --select-header-bg: accessible.shade4;
    --select-header-fg: accessible.shade1;
}
body.dark {
    --primary: dark.primary;
    --danger: dark.danger;
    --success: dark.success;
    --info: dark.info;
    --reject: dark.reject;
    --bg: dark.bg;
    --fg: dark.fg;
    --select-header-bg: dark.shade4;
    --select-header-fg: dark.shade1;
}



/************/
/************/
/************/

card1()
    box-shadow: 2px 5px 6px rgba(0,0,0,0.3)

card0()
    box-shadow: 0px 0px 8px rgba(0,0,0,0.3)

nowrap()
    white-space: nowrap;



.swal2-footer {
    border: none !important; // default solid border is ugly
}

@require "global_styl/mixins.styl";
@require "global_styl/global.styl";
@require "global_styl/bootstrap-esq.styl";
@require "global_styl/misc-ui.styl";
@require "global_styl/ogs-font.styl";
@require "../submodules/goban/src/Goban.styl";
@require "global_styl/swal.styl";
@require "global_styl/flags32.styl";
@require "global_styl/font-awesome.styl";
@require "global_styl/font-awesome-hacks.styl";
@require "global_styl/react-datetime.styl";
@require "global_styl/react-autosuggest.styl";

@require "lib/popover.styl";
@require "lib/toast.styl";
@require "lib/ogs-react-select.styl";

@require "./components/material/*.styl";
@require "./components/[A-Z]*/*.styl";
@require "./components/[A-Z]*/*/*.styl";
@require "./components/[A-Z]*/*/*/*.styl";
@require "./components/[A-Z]*/*/*/*/*/*.styl";

@require "./views/[A-Z]*/*.styl";
@require "./views/docs/*.styl";


// Put our theming into react-tabs css: copied from them and modified

.react-tabs {
  -webkit-tap-highlight-color: transparent;
}

.react-tabs__tab-list {
  border-bottom: 1px solid #aaa;
  margin: 0 0 10px;
  padding: 0;
}

.react-tabs__tab {
  display: inline-block;
  border: 1px solid transparent;
  border-bottom: none;
  bottom: -1px;
  position: relative;
  list-style: none;
  padding: 6px 12px;
  cursor: pointer;
}

.react-tabs__tab--selected {
  border-color: #aaa;
  themed background bg;
  themed color fg;
  border-radius: 5px 5px 0 0;
}

.react-tabs__tab--disabled {
  color: GrayText;
  cursor: default;
}

.react-tabs__tab:focus {
  outline: none;
}

.react-tabs__tab:focus:after {
  content: '';
  position: absolute;
  height: 5px;
  left: -4px;
  right: -4px;
  bottom: -5px;
  themed background bg;
}


.react-tabs__tab-panel {
  display: none;
}

.react-tabs__tab-panel--selected {
  display: block;
  padding: 10px;
}
