/*
 * Copyright (C)  Online-Go.com
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */
#Overview-Container {
    .welcome {
        margin-top: 2rem;
        text-align: center;
        font-size: x-large;
    }
}

#Overview {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    h3 >i {
        margin-right: 1rem;
    }

    .actions {
        display: flex;
        justify-content: center;
    }

    .actions-container {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-basis: 5rem;
        max-height: 100vh;
        max-height: 'calc(100vh - %s)' % navbar-height;
    }

    .active-games {
        flex-grow: 1;
        flex-basis: 20rem;
        width: 100%;
        max-width: 100%;
        align-self: center;
    }

    .no-active-games {
        flex-grow: 1;
        flex-basis: 20rem;
        display: flex;
        max-height: 80vh;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .learn-how-to-play {
        flex-grow: 0;
    }

    .left {
        flex: 1;
        min-width: 20rem;
        max-width: 100vw;
        display: flex;
        flex-direction: column;
        // overflow-x: auto;
    }

    .right {
        flex: 0;
        flex-basis: 20rem;
        min-width: 20rem;
        max-width: 100vw;
        text-align: left;
        margin-left: 1rem;

        a {
            display: block;

            img {
                height: 15px;
                width: 15px;
            }
        }
    }

    .overview-categories {
        padding-top: 1rem;

        a > i {
            margin-right: 0.5rem;
        }

        > div {
            margin-left: 2rem;
        }
    }

    .ProfileCard {
        margin-top: 2rem;
    }

    .rank-and-progress {
        display: inline-flex;
        align-items: center;

        .progress {
            flex: 1;
        }
    }

    span.UserRating {
        span.frac {
            font-size: 100%;
            themed: color shade2;
        }

        sup {
            padding-left: 0.2em;
        }
    }

    .progress-bar {
        span.UserRating {
            span.frac {
                color: #eee;
            }

            sup {
                color: #eee;
            }
        }
    }

    .ladder-rank {
        display: inline-block;
        width: 3rem;
        text-align: left;
        margin-right: 1rem;
    }

    .FriendList {
        min-height: 5rem;
        max-height: 20rem;
        overflow-y: auto;
    }

    .Overview-TournamentList, .Overview-LadderList, .Overview-GroupList {
        a, .clickable, .fakelink {
            themed: color text-color;
        }

        a:active, a:hover, .clickable:hover, .fakelink:hover {
            themed: color text-color-active;
        }
    }

    .invite {
        display: flex;

        .fa {
            margin-right: 0.5rem;
            cursor: pointer;
        }

        .fa-check {
            themed: color success;
        }

        .fa-times {
            themed: color reject;
        }
    }

    .translation-dialog {
        text-align: justify;

        .fab.reject {
            float: right;
        }

        .never-show-this-message-button {
            margin-top: 2rem;
        }
    }

    .moderation-offer {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 1rem;
    }
}
