.AnnulQueueModal {
    min-width: 80vw;
    min-height: 90vh;
    z-index: 1000;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .header {
        justify-content: space-between;
    }

    .body {
        min-height: 100%;
        position: relative;
    }

    .count {
        margin-right: 20px;
    }

    .game-list {
        width: 15%;
        height: 100%;
        position: absolute;
        top: 0px;
        left: 0px;
        bottom: 0px;
        border-right: 1px solid transparent;
        themed: border-color shade3;
        overflow-y: scroll;
        overflow-x: hidden;
        overscroll-behavior: contain;
        -ms-overflow-style: none; /* Internet Explorer 10+ */
        scrollbar-width: none; /* Firefox */
    }

    .game-list::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
    }

    .game-container {
        width: 85%;
        height: 90%;
        float: right;
        position: absolute;
        top: 0px;
        right: 0px;
        overflow-y: scroll;
        -ms-overflow-style: none; /* Internet Explorer 10+ */
        scrollbar-width: none; /* Firefox */
    }

    .game-container::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
    }

    .game {
        flex: 1;
        display: flex;
        flex-direction: row;
        align-items: stretch;
        justify-content: stretch;
        align-content: stretch;
        flex-wrap: wrap;
        max-width: 100%;
        overflow-y: scroll;
        overflow-x: hidden;
        overscroll-behavior: contain;

        .col {
            flex: 1;
            flex-basis: 45%;
            display: inline-flex;
            min-width: 20rem;
            flex-direction: column;
            align-items: stretch;
            justify-content: stretch;
            align-content: stretch;
            padding: 0.5rem;
        }
    }

    #move-tree-container {
        height: 3rem;
    }

    .button-bar {
        display: flex;
        position: absolute;
        height: 10%;
        bottom: 0px;
        right: 0px;
        align-items: center;
        justify-content: space-between;
        width: 85%;
    }

    .modal-actions, .close {
        margin: 0 0px;
    }

    .strikethrough {
        text-decoration: line-through;
        color: #444;
    }

    ul {
        width: 100%;
        height: 100%;
        border-right: 1px solid transparent;
        text-align: center;
        list-style-type: none;
        padding: 0;
        margin: 0;

        li {
            width: 100%;
            padding: 5px;

            &:hover {
                background-color: aliceblue;
                cursor: pointer;
            }

            &.selected {
                background-color: #2196f3;
                color: #ffffff;

                &.strikethrough {
                    color: #bbb;
                }
            }
        }
    }

    .gamelist-nav {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .icon-button {
        display: inline-flex;
        align-items: center;
        justify-content: center;
    }

    .nav-arrow {
        background: none;
        border: none;
        padding: 0;
        cursor: pointer;
        outline: none;
        box-shadow: none;
    }

    .nav-arrow:hover i, .nav-arrow:focus i {
        transform: scale(1.3);
        color: #2196f3;
    }

    .nav-arrow:hover, .nav-arrow:focus {
        background: none;
        border: none;
        box-shadow: none;
    }

    .nav-arrow.left {
        margin-right: 5px;
    }

    .nav-arrow.right {
        margin-left: 5px;
    }

    .nav-arrow:focus {
        outline: none;
    }

    .nav-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;

        span {
            font-size: 1.1rem;
        }
    }

    .gamelist-nav .fa {
        cursor: pointer;
        font-size: 1.7rem;
        transition: transform 0.3s ease;
        z-index: 6;
        margin: 15px;
    }

    .gamelist-nav .fa:hover {
        transform: scale(1.3);
        color: #2196f3;
    }

    .AnnulOverlay {
        min-height: 100%;
        min-width: 100%;
        height: 100%;
    }

    .overlay {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        themed: background-color bg;
    }

    .spinner-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 10px;
        border-radius: 5px;
    }

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }

    .spinner {
        border: 4px solid rgba(0, 0, 0, 0.1);
        border-left-color: #777;
        border-radius: 50%;
        width: 24px;
        height: 24px;
        animation: spin 1s linear infinite;
        margin-bottom: 5px;
    }
}

.bg-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 1;
}