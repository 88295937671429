.player-container {
    .player-flags {
        color: #FF8B00;
    }
}

.player-card-mod-controls {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 0.5rem 0 0.5rem;
    align-items: center;

    .fa {
        cursor: pointer;
    }
}
