.moderator-log {
    td {
        vertical-align: top;
    }

    .date {
        @extend .monospace;
        width: 7em;
        text-align: left;
        padding-right: 1em;
        font-size: 0.7rem;
    }

    .logging-user {
        padding-right: 1rem;
    }

    .action {
        @extend .monospace;
        font-size: 0.8rem;

        a {
            padding-right: 1rem;
        }
    }

    pre {
        margin: 0;
        white-space: pre-wrap;
        word-wrap: break-word;
    }

    .acknowledgement-event {
        themed: background shade5;
        themed: color success;
        font-size: smaller;
        font-style: italic;
    }

    .cm-action {
        float: right;
        font-size: smaller;

        .cm-action-action {
            themed: color danger;
            font-size: medium;
            margin-left: 1rem;
        }
    }

    .warning-event {
        themed: color danger;
    }
}