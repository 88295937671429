/*
 * Copyright (C)  Online-Go.com
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */
.InfoBall {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    position: relative;

    i {
        cursor: pointer;
    }

    .details {
        display: none;
    }

    &.force-show-details, &:hover {
        .details {
            display: inline-block;
            max-width: 20rem;
            width: 20rem;
            position: absolute;
            themed: background-color primary;
            themed: color colored-background-fg;
            border-radius: 0.5rem;
            padding: 0.5rem;
            z-index: z.infoball;
            top: 1.5rem;
            left: 0;
        }
    }

    .backdrop {
        display: inline-block;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: z.modal.backdrop;
    }

    &.force-hide-details {
        .details {
            display: none !important;
        }
    }
}
