/*
 * Copyright (C)  Online-Go.com
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */
.ChallengeDetailsReviewPane {
    width: 100%;
    max-width: 30rem;

    h4 {
        margin-bottom: 0;
        padding-bottom: 0;
    }

    dl {
        margin-top: 0 !important;
    }

    dt {
        width: 50% !important;
    }

    dd {
        width: 50% !important;
    }

    p {
        padding-left: 1rem;
    }

    p.cheat-warning {
        text-align: center;
    }

    .cheat-alert {
        color: chocolate; /* mmm chocolate! */
        margin-left: 0.5em;
        margin-right: 0.5em;
    }
}
