.rdh-help-item {
    z-index: 1000;
    background-color: blueviolet;
    border-style: solid;
    border-color: blueviolet;
    border-radius: 0.3rem;

    display: flex;
    flex-direction: column;
    padding: 0 0.3rem 0 0.3rem;
    max-width: 25rem;
}

.rdh-popup-dismissers {
    margin-top: 2px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: lightgrey;
    font-size: smaller;
    cursor: pointer;
}

.rdh-quit-flow {
    text-decoration: underline;
    margin-right: 0.4rem;
}

.rdh-popup-skip {
    text-decoration: underline;
}

.rdh-target-highlight {
    box-shadow: 0px 0px 5px 8px rgba(210, 105, 30, 0.3);
}