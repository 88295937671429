/*
 * Copyright (C)  Online-Go.com
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */
.ThemePreferences {
    .select-custom {
        font-size: larger;
        font-weight: bold;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        align-content: center;

        .title {
            display: inline-block;
            padding-right: 1rem;
        }
    }

    .small.board {
        padding-left: 0;
    }

    .MiniGoban.inline {
        height: 1.5rem;
        width: 10rem;
        margin-left: 3rem;
    }

    div.with-sample-goban {
        .left {
            display: inline-flex;
            align-items: center;
            width: 12rem;
        }
    }
}