/*
 * Copyright (C)  Online-Go.com
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */
logo-spinner-svg = svg-load('../assets/ogslogo.svg');

@keyframes logo-spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.Loading {
    .loading-spinner {
        background-image: logo-spinner-svg;
        // no-repeat center center fixed;
        background-size: contain;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        margin-left: 0.5rem;
        width: 2.5em;
        height: 2.5em;
        display: inline-block;
        animation: logo-spin 2s linear infinite;
        transition: opacity 1s ease-in;
    }

    &.small {
        .loading-spinner {
            width: 1em;
            height: 1em;
        }
    }

    &.large {
        .loading-spinner {
            width: 4em;
            height: 4em;
        }
    }

    &.slow {
        .loading-spinner {
            animation: logo-spin 5s linear infinite;
        }
    }
}

.LoadingPage {
    display: flex;
    flex: 1;
    min-height: 20rem;
    align-items: center;
    justify-content: center;
    animation: fadein 2s;
}
