/*
 * Copyright (C)  Online-Go.com
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */
.ChatDetails {
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
    border: 1px solid transparent;
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.16), 0px 1px 1px 0px rgba(0, 0, 0, 0.15);
    themed: background-color shade5;
    themed: border-color shade4;
    themed: color fg;
    padding: 0.3rem;

    hr {
        opacity: 1;
        border: 1px solid gray;
        width: 100%;
    }

    a {
        text-align: right;
    }

    h4 {
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .notify-option {
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
        display: flex;
        align-items: center;
        justify-content: space-between;

        label {
            flex: 1;
        }

        .notify-option-toggle {
            margin-left: 0.5rem;
        }
    }

    .fakelink {
        float: right;
    }

    .actions {
        margin-top: 0.1rem;
        display: inline-flex;
        flex-direction: column;
        justify-content: space-between;

        > button {
            min-width: 12rem;
            text-align: center;
            font-size: 0.8rem;

            .fa, .ogs-goban {
                padding-right: 0.1rem;
            }
        }

        .view-group {
            text-align: right;
        }
    }
}
