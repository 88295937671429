/*
 * Copyright (C)  Online-Go.com
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */
.IncidentReportIndicator {
    display: inline-flex;
    flex-direction: column;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    align-items: center;
    font-size: 0.9rem;
    cursor: pointer;
    themed: color shade3;

    .fa-exclamation-triangle.active {
        themed: color primary;
    }

    &:hover {
        .fa-exclamation-triangle {
            themed: color shade2;
        }

        .fa-exclamation-triangle.active {
            themed-lighten: color primary 30%;
        }
    }

    .count {
        font-size: font-size-smaller;

        &.active {
            themed: color fg;
        }
    }
}

.IncidentReportList {
    .report-header {
        display: flex;
        flex-direction: row;
        align-items: baseline;
        justify-content: space-between;

        .report-id {
            display: flex;
            margin-top: auto;
            padding-right: 1 em;

            // this is just for appearance, it no longer has a function
            button {
                cursor: default;
                pointer-events: none;
            }
        }

        .unclaimed {
            font-style: italic;
        }

        .Player, button {
            margin-left: auto;
        }
    }

    .spread {
        display: flex;
        justify-content: space-between;
        padding-top: 0.7rem;
    }

    #conversation {
        font-weight: bold;

        &:hover {
            cursor: pointer;
            text-decoration: underline;
        }
    }

    .incident {
        padding-bottom: 1rem;
        margin-bottom: 1rem;
        border-bottom: 1px solid transparent;
        themed: border-color shade3;

        h4.notes {
            margin: 0.5em 0 0 0;
        }

        .notes {
            user-select: text;

            .source-to-target-languages {
                display: block;
                text-align: right;
            }

            .translated {
                font-style: italic;
            }
        }
    }
}

.IncidentReportList-backdrop {
    position: fixed;
    display: flex;
    // min-height: 100%;
    // height: 100%;
    max-height: 100vh;
    top: navbar-height;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: z.incident-list;
    align-items: flex-start;
    align-content: stretch;
    justify-content: center;
}

.IncidentReportList-modal {
    position: fixed;
    overflow-y: scroll;
    padding: 0.5rem;
    width: 20rem;
    max-width: 100vw;
    max-height: 'calc(100vh - %s)' % navbar-height;
    display: inline-block;
    z-index: z.incident-list;
    right: 0;
    top: navbar-height;
    box-shadow: -2px 2px 1px 0px rgba(0, 0, 0, 0.16);
    themed: background-color bg;
    themed: border-color shade2;
}

.IncidentReportList-plain {
    padding: 0.5rem;
}
