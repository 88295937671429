
.ActiveAnnouncements {
    // text-align: left;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 60rem;
    margin: auto;
    margin-top: 1rem;
    text-align: center;
    padding: 0.5rem;

    div {
        text-align: left;
        display: inline-block;
        padding-right: 1.5rem;
        padding-left: 1.5rem;
    }

    .popup-menu {
        padding: 0;

        .popup-menu-button {
            margin-left: 5px;
        }
    }
}
