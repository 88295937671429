/*
 * Copyright (C)  Online-Go.com
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */
#Appeal {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 100vw;
    max-width: 40rem;
    margin: 0 auto;

    h1 {
        text-align: center;
    }

    .Card {
        width: 100%;
    }

    .Message {
        .header {
            margin-bottom: 1rem;
            display: flex;
            justify-content: space-between;
        }

        &.moderator {
        }

        &.player {
            themed: background-color malkovich-bg;
        }

        &.hidden {
            themed: background-color hidden-bg;
        }
    }

    .input-card {
        textarea {
            width: 100%;
            height: 10rem;
        }

        .submit-and-hidden {
            display: flex;
            justify-content: space-between;
        }
    }
}
