/*
 * Copyright (C)  Online-Go.com
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */
.TooltipContainer {
    position: relative;

    .Tooltip {
        display: none;
        themed: background-color toast-bg;
        width: 200px;
        border-radius: 2px;
        position: absolute;
        left: -220px;
        top: 50%;
        transform: translateY(-50%);
        box-sizing: border-box;
        padding: 0.5em;
        box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.4);

        &.disabled {
            opacity: 0;
        }

        .title {
            font-weight: bolder;
            margin: 0;
            themed: color toast-fg;
            font-size: 0.9em;
        }

        .text {
            margin: 0;
            font-size: 0.9em;
            themed: color toast-fg;
        }
    }
}

.TooltipContainer:hover {
    .Tooltip {
        display: block;
    }
}

.Tooltip::before {
    content: '';
    position: absolute;
    left: 100%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%) rotate(45deg);
    margin-left: -2px;
    border-color: transparent;
    themed: border-top-color toast-bg;
    themed: border-right-color toast-bg;
    border-width: 7px;
    border-style: solid;
}

@media only screen and (max-width: 600px) {
    .TooltipContainer:hover {
        .Tooltip {
            display: none;
        }
    }
}

@media only screen and (max-height: 500px) {
    .TooltipContainer:hover {
        .Tooltip {
            display: none;
        }
    }
}