/*
 * Copyright (C)  Online-Go.com
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */
.Dock i.fa-clock-o {
    // make it less imposing, it looks crap full-size
    font-size: 1.1rem;
}

.GameTimings {
    display: grid;
    grid-template-columns: auto auto auto auto;
    padding-left: 1rem;
    margin-bottom: 0.75rem;
    font-size: smaller;

    .timings-header {
        grid-column: 1 / span 4;
        text-align: center;
        padding-bottom: 0.3em;
    }

    .timing-slow-live {
        themed: background-color shade4;
    }

    .span-2 {
        grid-column: span 2;
    }

    .span-3 {
        grid-column: span 3;
    }

    .span-4 {
        grid-column: span 4;
    }

    .final-action-row {
        grid-row: 2; // final action time at the top for easy viewing in escape cases
        padding-bottom: 0.2rem;
    }
}
