/*
 * Copyright (C)  Online-Go.com
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */
.RengoManagementPane {
    width: 40rem;
    max-width: 100%;
    // take up as much vertical space as we are allowed
    display: flex;
    flex-direction: column;
    align-items: stretch;
    flex-grow: 1;

    .rengo-challenge-status {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .challenge-created-at {
            font-size: smaller;
            padding-right: 2rem; // avoid collision wiuth dismiss button
        }
    }

    // this is the child: the team management pane component (the second div we instantiate)
    > div:nth-of-type(2) {
        // this is where we want to consume vertical space
        flex-grow: 1;
    }

    .rengo-challenge-buttons {
        margin-top: 0.5rem; // stop buttons bumping into components above, if squashed up against them.
        // Yellow buttons on left, green on right
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        // put the buttons at the bottom of the containing element
        align-items: flex-end;
    }
}
