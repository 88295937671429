#RatingCalculator {
    display: flex;
    flex-direction: column;
    padding: 10px;
    justify-content: center;
    align-items: center;

    #Rating-Table-Div {
        table {
            max-width: 100vw;
        }

        input {
            width: 7rem;
            text-align: center;
        }

        #handicap-picker {
            width: 30%;
        }

        td {
            text-align: center;
            padding: 1rem;
            padding-left: 2rem;
            padding-right: 2rem;
            width: 12rem;
            min-width: 12rem;
        }

        @media (max-width: 700px) {
            td {
                padding: 1rem;
                padding-left: 1rem;
                padding-right: 1rem;
                min-width: 6rem;
            }
        }

        th, td {
            padding: 10px;
        }

        th {
            text-align: left;
        }
    }

    #player-auto-entries {
        display: flex;

        input {
            width: 30%;
        }
    }

    .rating-table-divider {
        border-right: 1px solid;
        themed: border-color shade3;
    }

    .rating-table {
        border-collapse: collapse;
    }

    h3 {
        text-align: left;
        margin-bottom: 0;
        margin-top: 1rem;
    }

    h2 {
        text-align: center;
        margin-bottom: 0.5rem;
    }
}
