.dropped-games-header {
    display: flex;
    align-items: center;

    i {
        margin-left: 0.5rem;
        font-size: 2em;
        font-weight: 400;
        cursor: pointer;
    }
}