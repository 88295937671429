.AutoTranslate {
    .translation-original {
        font-style: italic;
        font-size: smaller;
        themed: background-color shade5;
        padding: 0.5em;
    }

    .language-map {
        font-style: italic;
        font-size: smaller;
        text-align: right;
        themed: background-color shade5;
    }

    .placeholder {
        themed: color fg;
        font-style: italic;
        font-size: smaller;
        themed: background-color bg;
        padding: 0.5em;
    }
}
