
#FlaggedGames {
    table {
        td {
            padding: 1rem;
            padding: 1rem;
        }
    }

    .Player {
        min-width: 8rem;
    }

    .flags {
        padding: 1rem;
    }
}
