
.reported-game-container {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: stretch;
    align-content: stretch;
    flex-wrap: wrap;

    .reported-game h3 {
        margin-bottom: 0;
    }

    .reported-game-element {
        flex: 1;
        flex-basis: 40%;
        display: inline-flex;
        min-width: 20rem;
        max-width: 30rem;
        flex-direction: column;
        align-items: stretch;
        justify-content: stretch;
        align-content: stretch;
        padding: 0.5rem;

        .event {
            padding-right: 0.3rem;
        }
    }

    .reported-game-mini-goban {
        max-width: 20rem;
        max-height: 20rem;
        padding: 2rem;
    }

    #move-tree-container {
        height: 2rem;
    }

    .reported-game-info {
        order: -1; // put this first so mobile layout is nice

        .reported-turn {
            padding: 0.25rem;
        }
    }

    .moderator-reported-game-actions {
        padding: 0.5rem;
        text-align: center;
    }

    .reported-game-chat {
        max-width: 5rem;
        max-height: 15rem;
    }

    .reported-game-timing {
        text-align: center;
    }

    // We have horizontal less room than `GameLog` expects
    .GameLog {
        tr {
            vertical-align: top;
        }

        td>div {
            margin-bottom: 2em;

            span {
                display: inline-block;
            }
        }
    }
}
