.PlayControls {
    flex-grow: 0;
    flex-shrink: 0;
    display: block;
    user-select: none;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    padding-top: 0.5rem;
    max-width: 100vw;
    min-height: 5rem;

    // height: 23rem;
    // min-height: 5rem;
    > div {
        width: 100%;
    }

    .annulled-indicator {
        color: red;
        text-align: center;
        position: relative;
        cursor: help;

        .fa {
            themed: color shade1;
            margin-left: 0.5rem;
        }

        .annulment-reason {
            display: none;
        }
    }

    .annulled-indicator:hover {
        .annulment-reason {
            display: inline-block;
            position: absolute;
            top: 1.1rem;
            left: 50%;
            transform: translateX(-50%);
            themed: background-color shade4;
            border: 1px solid;
            themed: border-color shade3;
            z-index: z.popover;
            themed: color fg;
            padding: 0.5rem;
            border-radius: 0.5rem;
            max-width: 90vw;
            text-align: justify;
        }
    }

    .game-action-buttons {
        position: relative;
        text-align: center;
    }

    .analyze-mode-buttons {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        margin-top: 5px;
        margin-bottom: 5px;

        button {
            themed: background-color success;
            themed-darken: border-color success border-darken-amount;
        }

        button:hover {
            themed-lighten: background-color success button-highlight-brighten-amount;
            themed-darken: border-color success button-border-highlight-darken-amount;
        }
    }

    .pause-controls {
        text-align: center;
    }

    .play-buttons {
        height: 2.6rem;
        min-height: 2.6rem;
        display: flex;
        width: 100%;
        justify-content: space-between;

        > span {
            flex-grow: 1;
            flex-basis: 33%;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        > span:first-child {
            justify-content: flex-start;
        }

        > span:last-child {
            justify-content: flex-end;
        }
    }

    .stone-removal-controls {
        text-align: center;

        .explanation {
            padding: 1rem;
            font-style: italic;
        }
    }

    .cancel-button {
        /*
        position: absolute;
        right: 0.5em;
        bottom: 0.5em;
        */
    }

    .undo-button {
        // position: absolute;
        // left: 0.5em;
    }

    .accept-undo-button {
        /*
        position: absolute;
        left: 0.5em;
        bottom: 0.5em;
        */
    }

    .stalling-score-estimate {
        margin: 0.5rem;
        font-size: 1rem;
        font-style: italic;
        text-wrap: balance;
        text-wrap-mode: balance;
    }

    .needs-sealing {
        margin: 1rem;
        padding: 1rem;
        font-weight: bold;
        border-radius: 0.2rem;
        font-size: 1.1rem;
        themed: background-color warning-bg;
        themed: color warning-fg;

        .needs-sealing-coordinates {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 1rem;
            font-weight: bold;
            themed: background-color warning-bg;
            themed: color warning-fg;

            .needs-sealing-box {
                flex-grow: 0;
                flex-shrink: 0;
                flex-basis: 1rem;
                display: inline-block;
                width: 1rem;
                height: 1rem;
                background-color: red;
                border: 0.2rem solid #E079CE;
                margin-right: 0.5rem;
            }

            div {
                flex: 1;
            }
        }
    }

    .autoscoring-in-progress {
        margin-top: 0.5rem;
        font-size: 1.2rem;
    }

    .score-square {
        display: inline-block;
        width: 0.7rem;
        height: 0.7rem;
        border: 0.3rem solid #f00;

        &.black {
            background-color: black;
            border-color: #555;
        }

        &.white {
            background-color: white;
            border-color: #AAA;
        }
    }

    .btn-group {
        vertical-align: middle;
        display: inline-flex;
    }

    .removal {
        color: red;
    }
}