/*
 * Copyright (C)  Online-Go.com
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */
.Firewall {
    max-width: 70rem;
    margin-left: auto;
    margin-right: auto;

    .firewall-rule-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-bottom: 0.5rem;

        .notes {
            flex-grow: 2; // ensure the notes have as much room as possible
            margin-right: 5rem; // A slightly hacky way to stop it bumping into the owner in an ugly way
        }
    }

    .new-rule {
        themed: background-color shade5;
        margin-top: 2rem;
        padding: 0.5rem;
        margin-bottom: 4rem;
    }

    .config {
        > span, > input {
            margin-left: 1rem;
        }
    }

    .RuleEditor {
        padding-left: 2rem;

        // display: flex;
        // flex-direction: column;
        // justify-content: stretch;
        select {
            margin: 0.5rem;

            &.field {
                themed: background-color primary;
            }

            &.operator {
                themed: background-color success;
            }
        }

        input[type='number'] {
            width: 7rem;
        }

        input[type='text'] {
            width: 40rem;
        }

        .fa-trash {
            cursor: pointer;
        }
    }

    .buttons {
        text-align: right;
        margin: 1rem;
        padding-bottom: 3rem;
    }

    .test-results {
        display: flex;
        flex-direction: column;
        flex-align: center;
        align-items: center;
        justify-content: center;
    }

    .TestResultRow {
        display: inline-block;
        width: 50rem;
        max-width: 90vw;

        > span {
            margin-left: 1rem;
        }
    }

    .NetworkEditor {
        .NetworkEditorRow {
            input {
                margin-left: 1rem;
            }
        }
    }

    .RecentMatches {
        display: grid;

        .MatchRow {
            span {
                margin-left: 1rem;
            }
        }
    }
}
