/*
 * Copyright (C)  Online-Go.com
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */
.Tournament {
    display: flex;
    flex-direction: column;
    align-items: stretch;

    // here
    dt {
        white-space: nowrap;
    }

    .top-details {
        display: flex;
        justify-content: space-between;

        > div {
            flex: 1;
        }

        .top-right-details {
            display: flex;
            justify-content: center;
        }

        th {
            text-align: right;
            padding-right: 0.5rem;
            white-space: nowrap;
            width: 50%;
        }

        td {
            text-align: left;
            padding-left: 0.5rem;
            white-space: nowrap;
            width: 50%;
        }

        padding: 1rem;

        h2 {
            margin-top: 0;
        }

        border-bottom: 1px solid transparent;
        themed: border-color shade3;

        .start-time-edit {
            margin-top: 1rem;
            display: flex;
            align-items: flex-end;
        }

        input[type='checkbox'] {
            width: 2rem;
        }

        input[type='number'] {
            width: 5rem;
        }

        select {
            width: 10rem;
        }

        select.rank-selection {
            width: 5rem;
        }
    }

    .bottom-details {
        flex-grow: 1;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;

        .EmbeddedChatCard {
            flex-shrink: 0;
            flex-grow: 1;
            min-width: 15rem;
            flex-basis: 15rem;
            overflow: hidden;

            .Chat {
            }
        }

        .results {
            flex-shrink: 1;
            flex-grow: 1;
            flex-basis: 40rem;
            // overflow: auto;
        }

        .player-list {
            padding-top: 1rem;
            flex-shrink: 0;
            flex-grow: 1;
            text-align: left;
            min-width: 15rem;
            flex-basis: 15rem;

            .Card {
                overflow-x: hidden;
                overflow-y: auto;
                height: 10rem;
                flex-grow: 1;
            }

            display: flex;
            flex-direction: column;
            justify-content: stretch;

            .invite-input {
                flex-grow: 0;

                .input-group {
                    display: flex;
                    justify-content: center;
                }
            }

            .player-count {
                text-align: center;
            }
        }

        .sign-up-area {
            flex-grow: 1;
            text-align: center;
            min-width: 20rem;
            flex-basis: 30rem;
        }
    }

    .bottom-details.not-started {
        align-items: stretch;
    }

    .round-group {
        margin-top: 8em;
        text-align: left;
        display: flex;
        flex-direction: column;
        align-items: center;

        th {
            white-space: nowrap;
        }

        .rank {
            text-align: center;
            padding-left: 1rem;
            padding-right: 1rem;
        }

        .player {
            text-align: left;
            padding-right: 1rem;
        }

        .rotated-title {
            width: 3rem;
            position: relative;
            padding-bottom: 1.7em;
            text-align: right;

            .rotated {
                display: block;
                position: absolute;
                white-space: nowrap;
                transform-origin: 1rem 0;
                transform: rotate(-35deg);
            }
        }

        .result {
            text-align: center;
        }

        .points {
            width: 3rem;
            text-align: center;
        }
    }

    .win {
        @extends .result-won;
    }

    .tie {
        @extends .result-tie;
    }

    .loss {
        @extends .result-lost;
    }

    .no-result {
        // @extend .result-none
        // background-color: mix(@body-bg, #888, 80%);
    }

    .hide-details-note {
        font-size: larger;
        margin: 1rem;
    }

    @media (max-width: 800px) {
        .top-details {
            flex-wrap: wrap;

            .top-right-details {
                width: 100vw;
            }

            table {
                width: 100%;
            }
        }
    }

    .EmbeddedChatCard {
        height: 20rem;
    }

    .Dropzone {
        border: 2px dashed #888;
        padding: 2rem;
        text-align: center;
    }

    .download {
        padding: 1rem;
        text-align: center;
    }

    .tournament-elimination-container {
        position: relative;

        .match-div, .bye-div {
            position: absolute;
            height: 2.5em;
            width: 12em;
        }

        .black, .white, .bye {
            // border: 1px solid mix(@body-bg, #888, 30%);
            white-space: nowrap;
            overflow: hidden;
        }

        .black {
        }

        .white {
        }

        .bye {
        }

        .winner {
        }

        .matchdiv {
        }

        .byediv {
        }

        svg {
            z-index: -1;
        }

        .user_icon {
            margin-top: -4px;
        }

        .elimination-player-hover {
            themed: background-color success;

            .Player.player-name.clickable span {
                themed: color colored-background-fg !important;
            }
        }

        .ogs-goban {
            themed: color fg;
            text-decoration: none;
            position: absolute;
            margin-top: 2px;
        }

        a.elimination-game {
            position: relative;
            padding-right: 1.3em;
        }
    }

    .OpenGothaTournamentRound {
        // dispay: flex;
        // flex-direciton: row;
        align-items: center;
        justify-content: center;
        align-content: center;

        h3 {
            text-align: center;
        }

        select {
            min-width: 20rem;
            max-width: 100vw;
        }

        .scheduled-matches {
            margin: auto;

            .player1, .player2 {
                width: 7rem;
                overflow: hidden;
                padding-right: 1rem;
                padding-left: 1rem;
            }

            .player1 {
                padding-left: 8rem;
            }

            .handicap {
                display: inline-block;
                width: 4rem;
            }
        }

        .round-td-controls {
            text-align: center;
            margin: 1rem;
        }

        .round-group {
            margin-top: 2rem !important;
        }
    }

    .OpenGothaUploadDownload {
        display: flex;
        flex-direction: row;
        margin: 1rem;

        > div {
            flex: 50;
            display: flex;
            flex-direction: column;
            align-items: center;
            align-content: center;
            justify-content: center;
            cursor: pointer;
        }

        .fa {
            font-size: 4rem;
            margin: 1rem;
        }
    }

    .roster-rounds-line {
        display: flex;
        align-content: center;
        margin-top: 0.5rem;

        button {
            flex: 0;
            flex-basis: 7rem;
        }

        .Steps {
            flex: 1;
            margin-right: 7rem;
        }
    }

    .OpenGothaRoster {
        display: flex;
        padding: 1rem;
        justify-content: center;
    }

    .round-notes {
        p, h1, h2, h3, h4, h5 {
            text-align: center;
        }

        .round-notes-edit {
            display: flex;
            flex-direction: column;
            padding: 1rem;
            justify-content: center;
            align-items: center;

            textarea {
                width: 20rem;
                max-width: 100vw;
            }
        }
    }

    .final-results {
        border: 2px solid transparent;
        themed: border-color fg;
        width: 24rem;
        max-width: 85vw;
        margin-left: auto;
        margin-right: auto;
        padding: 1rem;
        margin-top: 0.5rem;

        h2 {
            margin-top: 0;
            font-size: larger;
            text-align: center;
        }

        > div {
            display: flex;
            align-items: center;
        }

        > div > span {
            height: 2em;
            display: inline-flex;
            align-items: center;
            line-height: 2em;
        }

        .final-results-place {
            width: 9rem;
            line-height: 1em;
            padding-right: 0.5em;
            font-weight: bold;

            img {
                margin-right: 0.5em;
            }
        }
    }
}
