/*
 * Copyright (C)  Online-Go.com
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */
.MergeReportModal {
    width: 30rem;
    max-width: 100%;
    height: 55vh;
    max-height: 100%;

    .body {
        display: flex;
        align-items: stretch;
        justify-content: stretch;
        align-content: stretch;

        .lines {
            flex: 1;
            display: flex;
            flex-direction: column;

            .line {
                border-bottom: 1px solid #000;
                themed: border-bottom-color shade3;
                margin-bottom: 0.2rem;
            }

            .fixed {
                display: inline-block;
                themed: color shade1;
                padding-right: 0.5rem;
                margin-right: 0.5rem;
                min-width: 2rem;
                text-align: right;
                border-right: 1px solid #000;
                themed: border-right-color shade3;
            }

            .line.merge-error {
                themed: color reject;
            }

            .line.merge-warn {
                themed: color danger;
            }

            .line.merge-info {
            }
        }
    }
}
