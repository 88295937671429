.AvailableQuickMatches-container {
    padding-top: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.AvailableQuickMatches {
    max-width: 40rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 0.5rem;
    justify-items: center;
}
