/*
 * Copyright (C)  Online-Go.com
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */
#SignIn {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;

    .Card {
        padding: 1rem;
        width: 15rem;
        max-width: 100%;

        h2 {
            margin-top: 0;
        }

        input {
            width: 100%;
            margin-bottom: 1rem;
        }

        .form-actions {
            margin-bottom: 1rem;
            display: flex;
            justify-content: space-between;
            align-items: baseline;
        }
    }

    .registration {
        margin-top: 2rem;
        text-align: center;

        h3 {
            margin-bottom: 0.5rem;
        }

        .fa-sign-in {
            margin-right: 0.5rem;
        }

        input {
            width: 100%;
            margin-bottom: 1rem;
        }
    }

    .form-actions {
        margin-bottom: 1rem;
        display: flex;
        justify-content: space-between;
        align-items: baseline;
    }

    .registration {
        margin-top: 1rem;
        text-align: center;

        .fa-sign-in {
            margin-right: 0.5rem;
        }
    }

    .LineText {
        themed: color shade1;
        margin-bottom: 1rem;
    }
}

.email-icon, .google-oauth2-icon, .twitter-icon, .facebook-icon, .apple-id-icon, .github-icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    font-size: 20px;
    background-size: cover;
    margin-right: 0.5rem;
}

.google-oauth2-icon {
    background-image: svg-load('../assets/google-icon.svg');
}

.facebook-icon {
    background-image: svg-load('../assets/facebook-icon.svg');
}

.twitter-icon.fa-twitter {
    color: #1DA1F2;
    margin-right: 0.5rem;
}

.apple-id-icon.fa-apple {
    color: #888;
    margin-right: 0.5rem;
}

.github.fa-github {
    color: #888;
    margin-right: 0.5rem;
}

.email-icon.fa-envelope-o {
    color: #888;
    margin-right: 0.5rem;
}
