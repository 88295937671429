/*
 * Copyright (C)  Online-Go.com
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */
.GameInfoModal.Modal {
    width: 100%;
    max-width: 40rem;

    .header {
        display: flex;
        flex-direction: column;
    }

    h3>span {
        margin: 0 0.3rem 0 0.3rem;
    }

    .rengo-teams-container {
        margin-top: 0.5rem;
        display: flex;
        flex-direction: row;
        width: 100%;
        overflow: scroll;

        .rengo-team-list {
            display: flex;
            flex-direction: column;
            align-items: center;
            flex-grow: 1;

            span.team-title {
                border-bottom: solid 1px;
                margin-bottom: 0.3rem;
            }
        }
    }

    dt {
        width: 30%;
    }

    dd {
        width: 70%;
    }
}
