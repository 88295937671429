/*
 * Copyright (C)  Online-Go.com
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */
#OnlineLeaguePlayerLanding {
    display: flex;
    flex-direction: column;
    align-items: center;

    .login-options {
        display: grid;
        grid-template-columns: 1fr 0.4fr;

        h3 {
            grid-column: 1 / span 2;
        }

        span {
            justify-self: end;
            align-self: center;
            padding-right: 0.5rem;
        }
    }

    .match-detail {
        margin-bottom: 2rem;
    }

    .waiting-chat {
    }

    .unstarted-match {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;

        .waiting-chat {
            width: 80%;
            max-width: 100rem;
            max-height: 20rem;
            margin-bottom: 1rem;
            overflow-y: scroll;

            .EmbeddedChatCard {
                min-height: 4rem;
            }
        }
    }
}
