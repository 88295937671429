.MultipleContainers {
    .Wrapper {
        display: flex;
        width: 100%;
        box-sizing: border-box;
        justify-content: flex-start;

        &.center {
            justify-content: center;
        }
    }
}