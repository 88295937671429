.ReportsCenterSettings {
    margin-left: 1rem;

    input[type='number'] {
        width: 4rem;
        margin: 0;
        padding: 0;
    }

    th {
        margin-top: 0.5rem;
        margin-left: 1rem;
        margin-right: 1rem;
        text-align: left;
    }

    th, td {
        padding-left: 1rem;
        vertical-align: middle;
        height: 2rem;
    }

    td {
        vertical-align: middle;
    }

    .center {
        display: inline-flex;
        align-items: center;
        height: 2rem;
    }

    footer {
        margin: 1rem;
    }
}
