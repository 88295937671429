/*
 * Copyright (C)  Online-Go.com
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */
.Player {
    themed: color user;
    cursor: pointer;
    white-space: nowrap;
    display: inline-flex !important;
    max-width: 100%;
    align-items: center; // This centers not only the name, icon and rank, but also the presence indicator

    &:hover {
        text-decoration: underline;
    }

    &.nolink {
        cursor: auto;

        &:hover {
            text-decoration: none;
        }
    }

    .flag {
        margin-right: 0.3rem;
        margin-left: 0.1rem;
    }

    // Styling to support the "ShowPlayersInReportContext" context that we provide
    .show-players-in-report & {
        align-items: center;

        &::after {
            padding-left: 0.25rem;
            width: 0.8rem;
            font-family: 'FontAwesome';
            font-size: 0.7rem;
            content: '';
        }

        &.reported::after {
            content: fa-exclamation-triangle-content;
            themed: color danger;
        }

        &.reporter::after {
            content: fa-bullhorn-content;
            themed: color supporter;
        }
    }

    &.with-flare {
        align-items: baseline; // if we have flare, then we don't have presence indicator, and it's better to line up the baselines (looks better in chat)
    }

    &.with-flare:before {
        width: 0.8rem;
        font-family: 'FontAwesome';
        font-size: 0.7rem;
        content: ' ';
    }

    &.with-flare.supporter:before {
        themed: color supporter;
        content: fa-circle-content;
    }

    &.with-flare.professional:before {
        themed: color professional;
        content: fa-graduation-cap;
        padding-right: 0.2rem;
    }

    &.with-flare.moderator:before {
        themed: color moderator;
        content: fa-gavel-content;
    }

    &.with-flare.admin:before {
        themed: color admin;
        content: fa-wrench-content;
    }

    &.with-flare.dragon:before {
        content: ' ';
        width: 0.75rem;
        height: 0.75rem;
        display: inline-block;
        background-image: svg-load('../assets/dragon-icon.svg');
        background-size: cover;
        margin-left: -0.05rem;
        margin-right: 0.05rem;
    }

    &.with-flare.aga:before {
        content: ' ';
        width: 0.75rem;
        height: 0.75rem;
        display: inline-block;
        background-image: url('https://cdn.online-go.com/assets/agaico1.png');
        background-size: cover;
        margin-left: -0.05rem;
        margin-right: 0.05rem;
    }

    &.timeout {
    }

    &.supporter {
        themed-important: color supporter;
    }

    &.professional {
        themed-important: color professional;
    }

    &.ambassador {
        themed-important: color ambassador;
    }

    &.teacher {
        themed-important: color teacher;
    }

    &.bot {
        themed-important: color bot;
    }

    &.moderator {
        themed-important: color moderator;
    }

    &.aga {
        themed-important: color aga-official;
    }

    &.admin {
        themed-important: color admin;
    }

    &.guest {
        color: #888;
        cursor: default;
        text-decoration: none;
    }

    & span.rank span.timeout {
        font-weight: bold;
    }

    // ---
    // This is the chat presence indicator in the PM chat dialog box ...
    // ... and in the Friends list, where it is currently not aligned properly, despite vertical-align: middle !
    &.online, &.offline {
        position: relative;
        padding-left: 0.6rem;

        &:before {
            display: inline-flex;
            align-items: center;
            font-size: 0.5rem;
            height: 1rem;
            width: 0.5rem;
            font-family: 'FontAwesome';
            content: fa-circle-content;
            themed: color chat-offline;
            text-shadow: 0 0 1px rgba(0, 0, 0, 0.5);
            padding-right: 0.25rem;
        }
    }

    &.online:before {
        themed: color indicate-online;
    }

    // ---
    .Player-username {
        flex: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-left: 0.25rem;
    }

    .Player-rank {
        flex: 0;
        font-size: 0.7em;
        padding-left: 0.35em;
        align-self: center;
    }

    &.fa-clipboard {
        transform: scale(0.7);
        padding-left: 3px;
        vertical-align: middle;
    }
}

.Player-with-icon {
    .PlayerIcon {
        margin-right: 0.5rem;
    }
}

.player-name-container span.Player {
    color: inherit;
}
