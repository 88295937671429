/*
 * Copyright (C)  Online-Go.com
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */
.AccountWarning-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: z.account-warning;
    background-color: rgba(0, 0, 0, 0.5);
}

.canned-message {
    white-space: pre-line;
}

.AccountWarning {
    position: fixed;
    top: auto;
    bottom: auto;
    left: auto;
    right: auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 30rem;
    max-width: calc(95vw - 2rem);
    max-height: calc(95vh - 2rem);
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
    z-index: z.account-warning;
    themed: background-color info;
    themed: color colored-background-fg;
    border-radius: 0.5rem;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .AutoTranslate {
        font-size: 1.5rem;
        text-align: justify-content;
        line-height: 1;
        overflow: auto;
        padding: 0;
        margin: 0;

        strong {
            text-decoration: underline;
        }
    }

    .space {
        flex-grow: 1;
        height: 1rem;
    }

    .buttons {
        font-size: 1.5rem;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        user-select: none;
    }

    button {
        font-size: 1.1rem;
    }

    input[type='checkbox'] {
        margin-right: 0.5rem;
        width: 1.2rem;
        height: 1.2rem;
    }

    label {
        cursor: pointer;
        margin-right: 1rem;
    }
}

.AccountWarningAck {
    position: fixed;
    top: auto;
    bottom: auto;
    left: auto;
    right: auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 30rem;
    max-width: calc(95vw - 2rem);
    max-height: calc(95vh - 2rem);
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
    z-index: z.account-warning;
    themed: background-color info;
    themed: color colored-background-fg;
    border-radius: 0.5rem;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .space {
        flex-grow: 1;
        height: 1rem;
    }

    .buttons {
        font-size: 1.5rem;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        user-select: none;
    }

    button {
        font-size: 1.1rem;
    }

    input[type='checkbox'] {
        margin-right: 0.5rem;
        width: 1.2rem;
        height: 1.2rem;
    }

    label {
        cursor: pointer;
        margin-right: 1rem;
    }
}
