/*
 * Copyright (C)  Online-Go.com
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */
.LineText {
    display: flex;
    align-items: center;
    align-content: stretch;

    .left, .right {
        flex: 1;
        border-bottom: 1px solid #888;
        themed: border-color shade3;
        margin-top: 0.25em;
    }

    .left {
        margin-right: 0.5rem;
    }

    .right {
        margin-left: 0.5rem;
    }

    .contents {
    }
}

@keyframes pulsate-reject {
    0%, 100% {
    }

    50% {
        box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.35);
        background-color: lighten(themes['light']['reject'], 15%);
    }
}

.flex {
    display: flex;
}

.flex.center {
    justify-content: center;
}

.flex.fill {
    flex-grow: 1;
}

.flex.shrink {
    flex-shrink: 1;
}

.flex.center-vertically {
    align-items: center;
}

.flex.center-both {
    align-items: center;
    justify-content: center;
}

.flex.stretch {
    align-items: stretch;
}

.SearchInput {
    position: relative;

    i.fa {
        position: absolute;
        bottom: 0.2em;
        left: 0.4rem;
        themed: color shade2;
    }

    input {
        padding-left: 1.6em;
    }
}

.h4rem {
    min-height: 4rem;
}

/* Tooltip */
.ogs-tooltip {
    position: absolute;
    border: 1px solid transparent;
    padding: 0.1rem;
    padding-bottom: 0.3rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    themed: background-color bg;
    themed: color fg;
    themed: border-color shade3;
}

/* * Spinner from http://tobiasahlin.com/spinkit/   * */
.spinner {
    width: 40px;
    height: 40px;
    position: relative;
    margin: 100px auto;
}

.double-bounce1, .double-bounce2 {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    themed: background-color toast-bg;
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    -webkit-animation: sk-bounce 2s infinite ease-in-out;
    animation: sk-bounce 2s infinite ease-in-out;
}

.double-bounce2 {
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
}

@keyframes sk-bounce {
    0%, 100% {
        -webkit-transform: scale(0);
    }

    50% {
        -webkit-transform: scale(1);
    }
}

@keyframes sk-bounce {
    0%, 100% {
        transform: scale(0);
        -webkit-transform: scale(0);
    }

    50% {
        transform: scale(1);
        -webkit-transform: scale(1);
    }
}

/* * Ribbon / Ribboned * */
.Ribboned {
    position: relative;
}

.Ribbon-container {
    overflow: hidden;
    display: block;
    position: absolute;
    top: -0.1rem;
    right: -0.1rem;
    width: 6rem;
    height: 6rem;

    .Ribbon {
        position: relative;
        font-weight: bold;
        padding: 0.4rem;
        top: 1rem;
        left: -1rem;
        width: 10rem;
        text-align: center;
        transform: rotate(45deg);
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.15);
        background-color: rgba(255, 255, 255, 0.3);

        &.primary {
            themed-lighten(background-color, primary, 40%);
            themed(color, colored-background-fg);
        }

        &.reject {
            themed-lighten(background-color, reject, 40%);
            themed(color, colored-background-fg);
        }

        &.success {
            themed-lighten(background-color, primary, 40%);
            themed(color, colored-background-fg);
        }
    }
}

.next {
    .Ribbon {
        themed-lighten(background-color, primary, 40%);
        themed(color, colored-background-fg);
    }
}

.done {
    .Ribbon {
        themed-lighten(background-color, success, 40%);
        themed(color, colored-background-fg);
    }
}

@keyframes slowstrobe-keyframes {
    0% {
        opacity: 0.5;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0.5;
    }
}

.slowstrobe {
    animation-name: slowstrobe-keyframes;
    animation-duration: 3s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
}

.flex-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .flex-col {
        flex: 1;
        flex-basis: 1rem;
        display: flex;
        flex-direction: column;
        max-width: 95vw;
    }
}
