/*
 * Copyright (C)  Online-Go.com
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */
#ForceUsernameChange-container {
    height: 90vh;
    width: 100%;
    max-height: 100vh;
    max-width: 100vw;
    display: flex;
    margin: 0;
    padding: 0;
    overflow: hidden;
    align-items: center;
    justify-content: space-around;
}

#ForceUsernameChange {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;

    .Card {
        padding: 1rem;
        width: 25rem;
        max-width: 100vw;

        h2 {
            margin-top: 0;
        }

        input {
            width: 100%;
            margin-bottom: 1rem;
            font-size: large;
        }

        .form-actions {
            margin-bottom: 1rem;
            display: flex;
            justify-content: space-between;
            align-items: baseline;
        }
    }

    .LineText {
        themed: color shade1;
        margin-bottom: 1rem;
    }
}
