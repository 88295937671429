#COOLAdmin {
    margin-left: 1rem;

    i {
        margin-left: 0.3rem;
    }

    .leagues-list {
        display: grid;
        grid-template-columns: auto 1fr;
        grid-gap: 10px;
    }

    #NewLeagueInput {
        margin-top: 1rem;
    }
}
