/*
 * Copyright (C)  Online-Go.com
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */
.GameLog {
    td {
        padding-right: 1rem;
        border-bottom: 1px solid transparent;
        themed: border-bottom-color shade3;
    }

    .timestamp {
        width: 12rem;
    }

    .field {
        margin-right: 1rem;
    }

    .auto-score {
        themed: background shade5;

        a.Player, .game-log-player {
            themed: color shade1;
        }
    }
}
