/*
 * Copyright (C)  Online-Go.com
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */
.FreeTrialSurvey-container {
    display: flex;
    justify-content: center;
    margin: 2rem 1rem;

    @media screen and (max-width: 850px) {
        margin: 2rem 0;
    }
}

.FreeTrialSurvey {
    border: 1px solid hsl(48, 100%, 40);
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    width: 60rem;
    max-width: calc(min(100%, 100vw));
    min-height: 12rem;
    overflow: auto;
    padding: 1rem;

    @media screen and (max-width: 850px) {
        flex-direction: column;
        height: 30rem;
        align-items: stretch;
        align-content: stretch;
        justify-content: stretch;
        width: 100%;
    }

    hr {
        margin: 1rem 0;
    }

    .other {
        display: flex;
        flex-direction: column;
    }

    .indent {
        margin-left: 2rem;
    }

    // The markdown auto-linkifies our online-go.com text, which isn't
    // important in this context and is just distracting
    a {
        themed: color fg;
    }

    textarea {
        height: 10rem;
    }

    .buttons {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        margin: 0.5rem;

        .translation-original, .language-map {
            display: none;
        }
    }
}
