/*
 * Copyright (C)  Online-Go.com
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */
.Moderator {
    // here
    td.timestamp {
        @extends .monospace;
        white-space: nowrap;
        font-size: font-size-smaller;
        width: 7rem;
        padding-top: 0.4rem;
    }

    .userlog-user {
        display: flex;
        flex-wrap: wrap;

        > div, span {
            display: inline-block;
            margin-left: 0.5rem;
            margin-right: 0.5rem;
            white-space: nowrap;
        }
    }

    .small {
        font-size: font-size-smaller;
    }

    .clip {
        overflow: hidden;
    }

    .quick-ban {
        white-space: nowrap;
    }

    .userlog {
        th, td {
            text-align: left;
        }
    }

    .hsearch {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: center;
    }

    .should-ban {
        color: red;
    }

    .user {
        max-width: 10rem;
    }
}
