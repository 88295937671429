/*
 * Copyright (C)  Online-Go.com
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */
.Ladder-container {
    display: flex;
    align-items: stretch;
    justify-content: center;
    max-height: 'calc(100vh - %s - 0.2rem)' % navbar-height;
    height: 'calc(100vh - %s - 0.2rem)' % navbar-height;
}

.Ladder {
    flex: 1;
    width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;

    .Ladder-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        max-width: 40rem;
        width: 100%;
        margin-bottom: 1rem;
        flex-wrap: wrap;

        h2 {
            margin: 0;
            display: inline-block;
        }
    }

    .ladder-configuration {
        th {
            padding-right: 0.5rem;
            text-align: right;
            width: 40%;
        }

        td {
            text-align: left;
            width: 60%;
        }
    }

    .AutoSizer-container {
        flex: 1;
        width: 100%;
        height: 100%;
        overflow-y: hidden;
        overflow-x: hidden;
    }

    .LadderRow-container {
        display: flex;
        justify-content: center;
        width: 100%;
    }

    .LadderRow {
        overflow: hidden;
        max-width: 30rem;
        width: 100vw;
        height: 30px;
        cursor: pointer;

        &.not-challengeable {
            .Player, .right {
                color: #888 !important;
            }
        }

        &.challengeable {
            .Player, .right {
                themed-important: color link-color;
            }
        }

        &:hover {
            themed: background-color shade4;
        }

        .Player {
            cursor: pointer;
        }

        .rank {
            @extends .monospace;
            display: inline-block;
            min-width: 5rem;
            max-width: 5rem;
            width: 5rem;
        }

        &.highlight {
            themed: background-color search-highlight;
        }

        .ladder-player {
            display: flex;
            flex-direction: row;
            // margin-bottom: 0.5rem;
            align-items: center;

            // font-size: font-size-big;
            .not-challengable {
                // font-size: font-size-small;
                themed: color shade2;
            }

            > .Player {
                display: inline-block;
                overflow: hidden;
            }

            .rank {
                margin-left: 0.1rem;
                white-space: nowrap;
            }

            .right {
                flex: 1;
                text-align: right;
                margin-right: 0.1rem;
                white-space: nowrap;

                .incoming {
                    text-align: right;
                }

                .outgoing {
                    text-align: left;
                }

                .incoming, .outgoing {
                    display: inline-block;
                    width: 1.2rem;
                }
            }
        }
    }
}

.Ladder-challenge-details {
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.2);
    border: 1px solid transparent;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.16), 0px 2px 5px 0px rgba(0, 0, 0, 0.15);
    themed: background-color shade4;
    themed: border-color shade3;
    themed: color fg;
    max-width: 240px;
    padding: 0.2rem;

    .challenge-list {
        display: block;

        .challenge-rank {
            @extends .monospace;
            margin-right: 0.3rem;
            display: inline-block;
            width: 3.5rem;
        }

        .challenge-link {
            display: block;
            margin-right: 1rem;
            white-space: nowrap;
            overflow: hidden;
            cursor: pointer;

            .Player {
                cursor: pointer !important;
            }
        }
    }

    .challenge-button-or-text {
        margin-bottom: 1rem;
    }

    .not-challengable {
    }
}
