/*
 * Copyright (C)  Online-Go.com
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */
.challenge-link-copy, .challenge-link-copied {
    border-radius: 0.3rem;
    padding: 0 0.3rem 0 0.3rem;
    themed: background-color shade1;
    z-index: z.challenge-link;
}

// In the case of the "copied" message, we want it to look like
// a nice single-line button.
.challenge-link-copied {
    white-space: nowrap;
    text-align: center;
    margin-top: 0.3rem;
}
