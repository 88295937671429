/*
 * Copyright (C)  Online-Go.com
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */
.LadderComponent {
    h2, h4 {
        text-align: center;
    }

    td {
        vertical-align: top;
    }

    // here
    .rank-column {
        text-align: center;
        width: 1rem;
    }

    .player-column {
        width: 2rem;
    }

    .challenge-column {
        text-align: left;
        width: 3rem;
        white-space: nowrap;
    }

    .not-challengable {
        themed: color shade3;
        font-size: 0.8rem;
    }

    th {
        height: 1.2rem;
        white-space: nowrap;
        overflow: hidden;
        text-align: left;
    }

    td {
        height: 1.2rem;
    }

    .challenge-link {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        font-size: 0.8rem;
    }

    .challenge-rank {
        font-size: 0.7rem;
        padding-right: 0.3rem;
    }

    .challenge-line {
        display: block;
        overflow: hidden;
        padding-left: 1rem;
    }

    .primary-player {
        display: inline-block;
        overflow: hidden;
    }
}
