/*
 * Copyright (C)  Online-Go.com
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */
#ChallengeLinkLanding {
    display: flex;
    flex-direction: column;
    align-items: center;

    .Card {
        max-width: 30rem;
        margin-bottom: 1.5rem; // Space above "already have an account"

        .invitation {
            display: flex;
            flex-direction: column;
            align-items: center;

            .invite-text {
                padding-bottom: 0.75rem;
            }
        }

        .buttons {
            button {
                display: block;
                margin: auto;
            }

            span {
                float: right;
                margin-right: 1rem;
            }
        }
    }

    button {
        // Space above the Sign In button.
        margin-top: 0.5rem;
    }
}
