/*
 * Copyright (C)  Online-Go.com
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */
channel-utils-height = 8.4rem;
channel-width = 20%;
channel-max-width = 25rem;
channel-min-width = 12rem;

.ChatView {
    position: fixed;
    top: 'calc(%s + 0.2rem)' % navbar-height;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    width: 100vw;
    max-width: 100vw;
    flex-direction: row;

    .ChatChannelList, .ChatUsersList {
        flex-basis: channel-width;
        flex-shrink: 0;
        flex-grow: 0;
        width: channel-width;
        max-width: channel-max-width;
        min-width: channel-min-width;
        // overflow-y: scroll;
        display: flex;
        flex-direction: column;
        user-select: none;
        flex-grow: 0;
        flex-shrink: 0;
        overflow-x: hidden;
        box-sizing: border-box;
        padding: 1rem;
        themed: background card-background-color;
    }

    .ChatChannelList {
        /* border-right: 1px solid transparent; */
        /* themed border-right-color shade3 */
    }

    .ChatUsersList {
        /* border-left: 1px solid transparent; */
        /* themed border-left-color shade3 */
    }

    .ChatLog {
        flex: 1;
        position: relative;
        border-right: 1px solid transparent;
        border-left: 1px solid transparent;
        border-top: 1px solid transparent;
        word-wrap: break-word;
        overflow-x: hidden;

        .input-container {
            border-bottom: 1px solid transparent;
        }
    }
}

.ChatHeader {
    .fa-list, .fa-users {
        display: none;
    }
}

@media (max-width: 800px) {
    .ChatChannelList {
        display: none !important;
    }

    .ChatUsersList {
        display: none !important;
    }

    .show-channels {
        .ChatChannelList {
            display: flex !important;
        }
    }

    .show-users {
        .ChatUsersList {
            display: flex !important;
        }
    }

    .ChatHeader {
        .fa-list, .fa-users {
            display: inline-flex;
        }
    }
}

@media (max-height: 300px) {
    .show-channels {
        .ChatChannelList {
            display: flex !important;
        }
    }

    .show-users {
        .ChatUsersList {
            display: flex !important;
        }
    }

    .ChatHeader {
        display: none;
    }
}

.EmbeddedChatCard {
    display: flex;

    .ChatLog {
        flex: 1;
    }
}
