.Report-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    z-index: z.report;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.2);
}

.Report {
    display: inline-flex;
    flex-direction: column;
    width: 25rem;
    height: 40rem;
    max-height: 90vh;
    max-width: 90vw;
    themed: color fg;
    border-radius: 0.5rem;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.5);

    .reported-details {
        flex: 0;
        display: flex;
        flex-direction: row;

        h3 {
            flex: 1;

            .PlayerIcon {
                float: right;
            }
        }

        .user-game-review-inputs {
            > div {
                text-align: left;
                display: flex;
                flex-direction: row;
                align-items: flex-end;
                justify-content: flex-start;

                input {
                    margin-left: 0.5rem;
                }

                &.game-review-numbers {
                    input {
                        width: 3rem;
                        margin-left: 0.5rem;
                        margin-right: 0.5rem;
                    }
                }
            }
        }
    }

    .type-picker {
        flex: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        align-content: stretch;
        gap: 0.3rem;

        select {
            width: 100%;
            font-size: 1.3rem;
        }

        .report-category-description {
            margin: 0.3rem;
        }
    }

    .details {
        flex: 1;
        display: flex;

        textarea {
            flex: 1;
        }
    }

    .notes {
        margin-bottom: 1px;
    }

    .characters-remaining-prompt {
        font-style: italic;
        font-size: smaller;
        text-align: end;
        themed: color shade1;
    }

    .buttons {
        flex: 0;
        display: flex;
        justify-content: space-between;
        margin-top: 1px;
    }

    .required {
        themed: border-color primary;

        input {
            themed: border-color primary;
        }
    }

    .reported-conversation {
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        themed: border-color shade3;
        max-height: 5rem;
        overflow-y: auto;
    }

    .required-text {
        themed: color danger;
        text-size: 1.1rem;
        padding: 0.5rem;
    }
}
