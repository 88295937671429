/*
 * Copyright (C)  Online-Go.com
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */
.MiniGoban {
    display: inline-flex;
    flex-direction: column;
    width: 22rem;
    height: 25rem;
    max-width: 100vw;
    max-height: 100vh;
    align-items: center;
    justify-content: center;
    themed: color fg;

    &:hover {
        themed: color fg;
        text-decoration: none;
    }

    &.link {
        cursor: pointer;
    }

    .minigoban-title {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 0.5rem;

        .game-date, .game-result {
            font-size: smaller;
        }
    }

    .inner-container {
        position: relative;
        display: inline-block;
        z-index: 1;
    }

    .player-rank {
        float: left;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        flex-shrink: 0;
        flex-basis: auto;
        margin-right: 0.5em;
        margin-left: 0.5em;
    }

    .player-name {
        float: left;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        flex-shrink: 1;
        flex-basis: auto;
    }

    .score {
        position: absolute;
    }

    .title-white, .title-black {
        position: absolute;
        display: flex;
        flex-direction: row;
        font-size: 0.9em;
        left: 1rem;
        right: 1rem;
        text-align: center;

        &.to-move {
            font-weight: bold;
        }
    }

    .title-black {
        top: 0;
        padding-bottom: 1em;
    }

    .title-white {
        bottom: 0;
    }

    .title-black .score {
        top: 1.7em;
        left: 0.15em;
        transform-origin: 0 0;
        transform: rotate(90deg);
    }

    .title-white .score {
        bottom: 2em;
        right: 0em;
        transform-origin: 100% 100%;
        transform: rotate(90deg);
    }

    .board {
        display: inline-block;
        cursor: pointer;
        padding: 1em;
        padding: 1.2em;
        border-radius: 4px;
        border: 1px solid transparent;
        themed: color fg;
    }

    .board.current-users-move {
        border: 1px solid transparent;
        border-radius: 4px;
        // padding: 1.2em;
        themed: background-color miniboard-to-move;
        themed: border-color miniboard-to-move-border;
    }

    .board.viewed-users-move {
        border: 1px solid transparent;
        border-radius: 4px;
        // padding: 1.2em;
        themed: background-color miniboard-viewed-to-move;
        themed: border-color miniboard-viewed-to-move-border;
    }

    .board.in-stone-removal-phase {
        border: 1px solid transparent;
        padding: 1em;
        themed: background-color miniboard-stone-removal;
        themed: border-color miniboard-stone-removal-border;
    }

    .board.finished {
        opacity: 0.8;
    }

    .board:hover {
        themed: background-color miniboard-hover;
        themed: border-color miniboard-hover-border;
    }

    .board.in-stone-removal-phase:hover {
        themed: background-color miniboard-stone-removal-hover;
        themed: border-color miniboard-stone-removal-hover-border;
    }

    .no_games {
        text-align: center;
        font-weight: bold;
        margin: 2em;
    }

    &.nolink {
        .board {
            background-color: transparent !important;
            border: none !important;
        }
    }
}
