.ReportsCenterHistory {
    margin-left: 1rem;

    td, th {
        padding-right: 0.5rem;
    }

    .history-options {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        width: 100%;
        margin-bottom: 10px;
    }

    .search {
        display: flex;
        align-items: flex-end;
    }

    .search i {
        font-size: smaller;
        margin-left: 1rem;
        padding-right: 0.25rem;
        padding-bottom: 0.4em;
    }

    .resolved {
        themed: color shade2;
    }

    .vote-counts {
        display: grid;
        grid-template-columns: 1fr auto; /* Action column takes up the remaining space, count column is as narrow as needed */

        .action-votes {
            display: contents; /* Makes action and vote count be controlled by the grid */

            .action {
                padding: 0.5em;
                align-self: start; /* Align text to the top of the grid cell */
                white-space: nowrap; /* we actally don't care about the overflow, it's fine to cut if off */
            }

            .vote-count {
                padding: 0.5em;
                align-self: start; /* Align text to the top of the grid cell */
            }
        }
    }

    .your-vote {
        text-align: center;
        padding-left: 0.5rem;
    }

    .escalated {
        text-align: center;
    }
}