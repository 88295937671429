
.ChatUsersList {
    flex-basis: 12rem;
    flex-shrink: 0;
    flex-grow: 0;
    overflow-y: auto;
    user-select: none;

    .user-header {
        margin-top: 0.5rem;
        text-transform: uppercase;
        font-weight: bold;
        font-size: font-size-smaller;
        margin-bottom: 0.2rem;
        cursor: pointer;

        .fa-sort-numeric-asc, .fa-sort-alpha-asc {
            margin-left: 0.2rem;
            width: 0.8rem;
        }
    }

    .Player {
        themed: color fg;
        font-weight: bold;
    }
}
