/*
 * Copyright (C)  Online-Go.com
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */
analysis-prediction-chart-height = 100px;

.AIReviewChart {
    height: analysis-prediction-chart-height;
    // margin-bottom: 1rem;
    // max-width: calc(100vw - 2em);
    max-width: calc(100vw);
    // max-width: 100%;
    user-select: none;

    svg {
        font-size: font-size-smaller;
    }

    .axis path, .axis line {
        fill: none;
        themed: stroke shade3;
        shape-rendering: crispEdges;
    }

    .brush {
        .selection {
            themed: stroke shade1;
            themed: fill shade1;
            fill-opacity: 0.2;
            shape-rendering: crispEdges;
        }
    }

    .loading, .nodata {
        height: analysis-prediction-chart-height;
        display: flex;
        justify-content: center;
        align-items: center;
        themed: color shade2;
    }

    .chart {
        cursor: crosshair;

        text {
            fill: #7F8FAF;
        }

        .line {
            fill: none;
            stroke-width: 1px;
            clip-path: url('#clip');
            shape-rendering: crispEdges;
        }

        /*
        .fast-prediction.line {
            stroke-width: 2px
            themed stroke chart-line
        }
        */
        .win-rate-area {
            stroke-width: 1px;
            themed: stroke chart-line;
            shape-rendering: crispEdges;
            fill: url('#win-rate-area-gradient');
        }

        .variation-win-rate-line {
            stroke-width: 1px;
            themed: stroke success;
            shape-rendering: crispEdges;
            fill: none;
        }

        .overlay {
            opacity: 0;
            pointer-events: all;
        }

        .data-point-circle {
            fill: red;
            stroke: red;
        }

        .crosshairs {
            themed: stroke shade3;
            stroke-width: 1px;
            shape-rendering: crispEdges;
        }

        /*
        .fast.crosshairs {
            themed stroke chart-line
        }
        */
        .full.crosshairs {
            themed: stroke chart-line;
        }

        .move.crosshairs {
            themed: stroke shade0;
        }

        .variation.move.crosshairs {
            themed: stroke success;
        }

        .cursor.crosshairs {
        }

        .win-loss-bar {
            shape-rendering: crispEdges;
        }
    }

    .deviation-area {
        themed: fill chart-area;
    }

    .timeline .axis {
        path, line {
            display: none;
        }
    }

    .y.axis {
        .tick {
            text {
                text-anchor: start !important;
                fill: #7F8FAF;
            }

            line {
                display: none;
            }
        }

        path {
            display: none;
        }
    }

    .date-legend-background {
        themed: fill shade2;
    }

    .date-legend-text {
        themed: fill colored-background-fg;
    }
}
