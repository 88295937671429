/*
 * Copyright (C)  Online-Go.com
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */
.react-autosuggest__container {
    position: relative;
}

.react-autosuggest__input {
    width: 100%;
}

/*
.react-autosuggest__input {
    width: 240px;
    height: 30px;
    padding: 10px 20px;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 16px;
    border: 1px solid #aaa;
    border-radius: 4px;
}
*/
.react-autosuggest__input:focus {
    outline: none;
}

.react-autosuggest__container--open .react-autosuggest__input {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
    display: none;
}

.react-autosuggest__container--open .react-autosuggest__suggestions-container {
    display: block;
    position: absolute;
    // top: 1.5rem;
    // width: 280px;
    width: 100%;
    border: 1px solid #aaa;
    background-color: #fff;
    // /font-size: 16px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 2;
}

.react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.react-autosuggest__container {
}

.react-autosuggest__container--open {
}

.react-autosuggest__input {
}

.react-autosuggest__suggestions-container {
}

.react-autosuggest__suggestions-list {
}

.react-autosuggest__suggestion {
}

.react-autosuggest__suggestion--highlighted {
    themed: background-color primary;
    themed: color colored-background-fg;
}

.react-autosuggest__section-container {
}

.react-autosuggest__section-title {
}
