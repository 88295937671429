.TurnIndicator {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: flex-end;
    cursor: pointer;

    .count {
        visibility: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        width: indiciator-size;
        height: indiciator-size;
        border-radius: 50%;
        text-align: center;
        border: 2px transparent;
        font-weight: 700;
    }

    .active.count {
        visibility: visible;
    }

    .inactive.count {
        visibility: visible;
    }
}

body.light .TurnIndicator {
    .count {
        background-color: #101010;
        color: #fff;
    }

    .inactive.count {
        background-color: #d2d2d2;
    }
}

body.dark .TurnIndicator {
    .count {
        background-color: #bbb;
        color: #252525;
    }

    .inactive.count {
        background-color: #535353;
    }
}

body.accessible .TurnIndicator {
    .count {
        background-color: #bbb;
        color: #252525;
    }

    .inactive.count {
        background-color: #535353;
    }
}

.TurnIndicatorV6 {
    display: inline-flex;
    flex-direction: column;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    align-items: center;
    font-size: 0.9rem;
    cursor: pointer;

    .ogs-goban {
        themed: color fg;
    }

    &:hover {
        .ogs-goban {
            themed-lighten: color fg 30%;
        }
    }

    .count {
        font-size: font-size-smaller;
    }
}
