/*
 * Copyright (C)  Online-Go.com
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */
.SupporterProblems-container {
    display: flex;
    justify-content: center;
    margin: 2rem 1rem;
}

.SupporterProblem {
    border-radius: 0.5rem;
    width: 60rem;
    max-width: 100%;
    border: 1px solid;
    padding: 0.5rem;
    font-size: 1.1rem;
    themed: background-color supporter-error-bg;
    themed: border-color supporter-error-border;

    .buttons {
        display: flex;
        justify-content: space-around;
        margin-top: 1rem;
    }
}