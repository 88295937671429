.AccountSettings {
    display: flex;
    flex-direction: column;

    .row {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        margin-bottom: 0.5rem;
        flex-wrap: wrap;
    }

    .left, .right {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex: 1;
        width: 45%;
        min-width: 20rem;
        padding-right: 1rem;
    }

    .bottom {
    }

    .password-update {
        padding: 0.5rem;
    }

    .save-button-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-top: 1rem;
    }
}
