/*
 * Copyright (C)  Online-Go.com
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */
.BanModal {
    width: 30rem;
    height: 30rem;
    max-height: 90vh;
    max-width: 90vw;
    text-align: center;

    .player-name {
        font-size: 1.5rem;
        margin: auto;
        padding-bottom: 1rem;
    }

    .Modal-content {
        textarea {
            width: 100%;
        }
    }

    .rdt { // react date time
        margin-bottom: 0.5rem;
    }
}
