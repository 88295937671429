/*
 * Copyright (C)  Online-Go.com
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */
.ChallengesList {
    .challenge-cards {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
    }

    .Card {
        width: 20rem;
        max-width: 100vw;

        .icon-name {
            flex-grow: 1;
            display: flex;

            .name {
                flex-grow: 1;
                min-width: 0;
            }

            .PlayerIcon {
                flex: 0;
                margin-right: 0.5rem;
                margin-bottom: 0.5rem;
            }

            h4 {
                margin: 0;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        .challenge-actions {
            text-align: right;
        }

        .fab {
            float: right;
            flex-shrink: 0;
        }
    }
}
