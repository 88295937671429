/*
 * Copyright (C)  Online-Go.com
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */
.swal2-container {
    z-index: z.swal !important;
}

.swal2-modal {
    themed-important: background-color bg;
    themed-important: color fg;

    h2 {
        themed-important: background-color bg;
        themed-important: color fg;
    }
}

.swal2-confirm, .swal2-cancel {
    font-size: 1.1rem;
}

.swal2-cancel {
    margin-right: 1rem;
}

.swal2-confirm {
    margin-left: 1rem;
}

.swal2-content {
    themed-important: background-color bg;
    themed-important: color fg;
}
