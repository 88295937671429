.BlockedVPN {
    width: 30rem;
    max-width: 90vw;
    margin: auto;
    font-size: 1.2rem;

    .collect {
        text-align: center;

        button {
            margin-left: 1rem;
        }
    }
}
