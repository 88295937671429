/*
 * Copyright (C)  Online-Go.com
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */
.ChatPresenceIndicator {
    // This is the not-present version of the indicator in the avatars on the game page
    themed: color chat-offline;
    themed: text-shadow chat-offline-shadow;

    // This does not appear to be used, because of the version in Players.styl
    &.online {
        color: pink; // flush out if it gets used somewhere with this pink and the below black
        text-shadow: -1px -1px 0 #000, 1px -1px 0 #8c8, -1px 1px 0 #8c8, 1px 1px 0 #6a6;
    }
}
