/*
 * Copyright (C)  Online-Go.com
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */
.DismissableNotification {
    themed-desaturate-and-lighten-darken: background-color info 80% 70%;
    themed-darken: border-color info 0%;
    border: 1px solid #000;
    text-align: center;
    margin: 0.5rem;
    padding: 0.5rem;
    border-radius: 0.5rem;

    .fa-times {
        themed: color shade2;
        float: right;
        cursor: pointer;

        &:hover {
            themed: color shade1;
        }
    }
}
