/*
 * Copyright (C)  Online-Go.com
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */
.Settings {
    // here
    user-select: none;

    .dev-tools {
        color: black;
        themed: background-color shade0;
        border: 1px solid red;
        border-radius: 10px;
        padding: 0 20px 20px 20px;
        margin-bottom: 20px;

        input {
            color: red;
            background-color: white;
        }
    }

    .normal-preferences.normal-preferences.hidden {
        display: none;
    }

    .PreferenceLine {
        max-width: 100vw;
        flex-wrap: wrap;
        margin-bottom: 2rem;
        align-items: center;

        .PreferenceLineTitle {
            display: inline-block;
            width: 20rem;
            min-width: 20rem;
            font-size: large;
            font-weight: bold;
        }

        &.title-on-top {
            align-items: flex-start;

            .PreferenceLineTitle {
                padding-top: 0.5rem;
            }
        }

        .PreferenceLineDescription {
            display: inline-block;
            width: 20rem;
            min-width: 20rem;
            font-size: small;
            font-weight: normal;
            font-style: italic;
        }

        .PreferenceLineBody {
            display: inline-flex;
            align-items: center;
            margin-left: 1rem;
            min-width: 10rem;
        }

        &.body-as-column {
            .PreferenceLineBody {
                flex-direction: column;
                align-content: flex-start;
                align-items: flex-start;
            }
        }

        span {
            margin-right: 0.2rem;
            margin-left: 0.2rem;
        }
    }

    .PreferenceDropdown {
        min-width: 10rem;

        .PreferenceDropdown-value-container {
            padding-left: 0.5rem;
            padding-right: 0.5rem;
        }
    }

    .ProfanityDropdown {
        min-width: 10rem;
    }

    .Card {
        margin-bottom: 1rem;
    }

    .fa-smile-o {
        font-size: 0.8em;
    }

    .fa-star {
        color: supporter-gold;
        margin-left: 0.3rem;
        margin-right: 0.3rem;
    }

    .vacation-status {
    }

    .vacation-container {
        div {
            margin-bottom: 1rem;
        }
    }

    h3 {
        margin-top: 0;
    }

    .volume-icon {
        cursor: pointer;
        width: 1rem;
        font-size: 1.4rem;
        margin-right: 0.5rem;
    }

    .notification-option {
        padding: 0.5rem;
        padding-left: 1rem;
    }

    .Settings-Card {
        > div {
            display: flex;
            flex-direction: row;
            align-items: center;
            flex-wrap: wrap;

            > h4 {
                display: inline-block;
                width: 10rem;
                max-width: 100vw;
                flex: 0;
                flex-basis: 10rem;
                margin-right: 2rem;
            }

            > h5 {
                display: inline-block;
                padding-left: 2rem;
                flex: 0;
                flex-basis: 15rem;
            }

            > span:first-of-type {
                margin-left: 3rem;
                margin-right: 1rem;
                flex: 0;
                flex-basis: 14rem;
            }

            .sound-option-select {
                text-wrap: nowrap;

                .sound-option {
                    padding-left: 0.5rem;
                }
            }

            .flag {
                margin-left: 0.3rem;
            }

            .volume {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-top: 1rem;
                margin-bottom: 1rem;

                input {
                    flex: 1;
                    display: inline-block;
                }

                i {
                    flex: 0;
                    padding-left: 0.5rem;
                    padding-right: 0.5rem;
                }
            }
        }
    }

    dt {
        margin-top: 2rem;
        font-weight: bold;
    }

    dd {
        margin-bottom: 0rem;
    }

    .password-update {
        div {
            padding-bottom: 0.5rem;
        }
    }

    .awaiting-validation-text {
        themed: background-color info;
        // themed color colored-background-fg;
        padding: 0.5rem;
        border-radius: 0.3rem;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
    }

    .SoundToggle {
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
        display: flex;
        align-items: center;
        max-width: 95vw;

        label {
            flex: 1;
            display: inline-flex;
            align-items: center;
            margin-right: 0.5rem;
            flex-basis: 14rem;

            .sound-toggle-name {
                flex: 1;
                flex-basis: 14rem;
            }

            .voice-or-effect {
                width: 3rem;
                flex-basis: 3rem;
                text-align: right;
            }
        }

        .Toggle {
            margin-right: 0.5rem;
            margin-left: 0.5rem;
        }

        .SoundToggle-voice-label {
            flex: 1;
            // flex-grow: 1;
        }
    }

    .PreferenceToggle, .EmailNotificationToggle {
        margin-top: 0.2rem;
        margin-bottom: 0.2rem;
        display: flex;
        align-items: center;

        label {
            display: inline-flex;
            align-items: center;
            margin-right: 0.5rem;

            .preference-toggle-name {
                width: 14rem;
                flex-basis: 14rem;
            }
        }

        .Toggle {
            margin-right: 0.5rem;
            margin-left: 0.5rem;
        }
    }

    .EmailNotificationToggle {
        margin-top: 1rem;
        margin-left: 1rem;

        label {
            .preference-toggle-name {
                width: 20rem;
                flex-basis: 20rem;
            }
        }
    }

    .sound-sample-left-column {
    }

    .col {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .LogoutButtons {
        flex: 1;

        > div {
            text-align: center;
            margin-top: 3rem;
        }
    }

    #LinkPreferences {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .LinkPreferencesDescription {
            display: inline-block;
            max-width: 30rem;
            text-align: justify;
            margin-bottom: 5rem;
        }

        .PreferenceLine {
            .PreferenceLineTitle {
                width: 15rem;
                min-width: 15rem;

                select {
                    width: 14rem;
                    min-width: 14rem;
                }
            }

            .PreferenceLineBody {
                width: 20rem;
                min-width: 20rem;

                input[type='number'] {
                    width: 7rem;
                }

                input[type='text'] {
                    width: 7rem;
                }

                .RankSelect {
                    margin-left: 1rem;
                }
            }
        }
    }

    #BlockedPlayers, #AnnouncementPreferences {
        .blocked-player-row {
            display: flex;
            margin-bottom: 0.5rem;
            flex-wrap: wrap;

            .blocked-player {
                width: 20rem;
                max-width: 100vw;
            }
        }
    }

    .Supporter .SiteSupporterText p {
        margin-top: 0;
        padding-top: 0;
    }
}

#SettingsContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    // align-items: stretch;
    align-items: flex-start;
}

#SettingsGroupSelector {
    border: 1px solid black;
    themed: border-color shade3;

    .SettingsGroup {
        cursor: pointer;
        padding: 0.7rem;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &.selected {
            themed: background-color primary;
            themed: color colored-background-fg;
        }

        .spacer {
            display: inline-block;
            width: 1rem;
        }

        i {
            display: inline-block;
            width: 1rem;
        }
    }
}

#SelectedSettingsContainer {
    flex: 1;
    display: flex;

    .help-details-greyed {
        themed: color shade4;
    }
}

// Mobile
@media (max-width: 767px) {
    #SettingsGroupSelector {
        display: none;
    }

    #SettingsContainer {
        flex-direction: column;
    }

    #SelectedSettingsContainer {
        padding-top: 1rem;
    }

    #SettingsGroupDropdown {
        .settings-group-container {
            padding-left: 1rem;
            padding-right: 1rem;
        }
    }

    #SettingsContainer {
        align-items: stretch;
    }
}

// Desktop
@media (min-width: 768px) {
    #SettingsGroupDropdown {
        display: none;
    }

    #SelectedSettingsContainer {
        padding-left: 1rem;
        padding-right: 1rem;
    }
}

.announcement-history {
    font-size: small;

    td, th {
        padding-right: 0.4rem;
    }

    .announcement-time {
        white-space: nowrap;
    }

    .announcement-message, .announcement-link {
        width: 17rem;
    }
}

.social-link {
    display: inline-flex;
    flex-direction: row;
    align-items: center;

    button {
        margin-left: 2rem;
    }
}

.GitHubUsername {
    img {
        height: 1rem;
        width: 1rem;
        margin-right: 0.5rem;
    }
}
